import { connect } from 'react-redux';
import { getBatchMedia } from '../../../../../../actions/forms';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { UPLOADED_DOCS } from '../../../../../../actions/constants';
const MediaFeature = ({ projectKey, batchMedia }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBatchMedia(projectKey));
  }, [dispatch, projectKey]);

  return (
    <>
      {Object.keys(batchMedia)?.map(key => {
        return (
          <Box key={key}>
            <Typography component={'h4'} variant='h4'>
              Batch: {key}
            </Typography>
            {batchMedia[key].map((media, index) => {
              return (
                <Box key={index}>
                  {/* Feedstock */}
                  {media?.dryFeedstockPhoto1URL ||
                    media?.dryFeedstockPhoto2URL ||
                    media?.dryFeedstockPhoto3URL ? (
                    <Typography sx={{ my: 2 }} component={'h5'} variant='h5'>
                      Feedstock
                    </Typography>
                  ) : null}
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '1rem',
                      mb: '1rem',
                    }}
                  >
                    {media?.dryFeedstockPhoto1URL ? (
                      <img
                        height='300'
                        width='300'
                        src={
                          UPLOADED_DOCS +
                          media?.dryFeedstockPhoto1URL?.split('/').pop()
                        }
                        alt='dryFeedstockPhoto1URL'
                      />
                    ) : null}
                    {media?.dryFeedstockPhoto2URL ? (
                      <img
                        height='300'
                        width='300'
                        src={
                          UPLOADED_DOCS +
                          media?.dryFeedstockPhoto2URL?.split('/').pop()
                        }
                        alt='dryFeedstockPhoto2URL'
                      />
                    ) : null}
                    {media?.dryFeedstockPhoto3URL ? (
                      <img
                        height='300'
                        width='300'
                        src={
                          UPLOADED_DOCS +
                          media?.dryFeedstockPhoto3URL?.split('/').pop()
                        }
                        alt='dryFeedstockPhoto3URL'
                      />
                    ) : null}
                  </Box>

                  {/* Burn Start */}
                  {media?.burnStartPhoto || media?.kilnIdPhoto ? (
                    <Typography sx={{ my: 2 }} component={'h5'} variant='h5'>
                      Burn Start
                    </Typography>
                  ) : null}
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '1rem',
                      mb: '1rem',
                    }}
                  >
                    {media?.burnStartPhoto ? (
                      <img
                        height='300'
                        width='300'
                        src={
                          UPLOADED_DOCS +
                          media?.burnStartPhoto?.split('/').pop()
                        }
                        alt='burn start'
                      />
                    ) : null}
                    {media?.kilnIdPhoto ? (
                      <img
                        height='300'
                        width='300'
                        src={
                          UPLOADED_DOCS + media?.kilnIdPhoto?.split('/').pop()
                        }
                        alt='kilnIdPhoto'
                      />
                    ) : null}
                  </Box>

                  {/* Moisture */}
                  {media?.moistureContentPhoto ? (
                    <Typography sx={{ my: 2 }} component={'h5'} variant='h5'>
                      Moisture
                    </Typography>
                  ) : null}
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '1rem',
                      mb: '1rem',
                    }}
                  >
                    {media?.moistureContentPhoto ? (
                      <img
                        height='300'
                        width='300'
                        src={
                          UPLOADED_DOCS +
                          media?.moistureContentPhoto?.split('/').pop()
                        }
                        alt='moisture content'
                      />
                    ) : null}
                  </Box>

                  {/* Bulk Density */}
                  {media?.bucketWeightPhoto || media?.emptyBucketPhoto ? (
                    <Typography sx={{ my: 2 }} component={'h5'} variant='h5'>
                      Bulk Density
                    </Typography>
                  ) : null}
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '1rem',
                      mb: '1rem',
                    }}
                  >
                    {media?.emptyBucketPhoto ? (
                      <img
                        height='300'
                        width='300'
                        src={
                          UPLOADED_DOCS +
                          media?.emptyBucketPhoto?.split('/').pop()
                        }
                        alt='emptyBucketPhoto'
                      />
                    ) : null}
                    {media?.bucketWeightPhoto ? (
                      <img
                        height='300'
                        width='300'
                        src={
                          UPLOADED_DOCS +
                          media?.bucketWeightPhoto?.split('/').pop()
                        }
                        alt='bucketWeightPhoto'
                      />
                    ) : null}
                  </Box>

                  {/* Biochar Measurement */}
                  {media?.biocharKilnPhoto ? (
                    <Typography sx={{ my: 2 }} component={'h5'} variant='h5'>
                      Biochar Measurement
                    </Typography>
                  ) : null}
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '1rem',
                      mb: '1rem',
                    }}
                  >
                    {media?.biocharKilnPhoto ? (
                      <img
                        height='300'
                        width='300'
                        src={
                          UPLOADED_DOCS +
                          media?.biocharKilnPhoto?.split('/').pop()
                        }
                        alt='biocharKilnPhoto'
                      />
                    ) : null}
                    {media?.biocharKilnPhoto2 ? (
                      <img
                        height='300'
                        width='300'
                        src={
                          UPLOADED_DOCS +
                          media?.biocharKilnPhoto2?.split('/').pop()
                        }
                        alt='biocharKilnPhoto2'
                      />
                    ) : null}
                    {media?.biocharKilnPhoto3 ? (
                      <img
                        height='300'
                        width='300'
                        src={
                          UPLOADED_DOCS +
                          media?.biocharKilnPhoto3?.split('/').pop()
                        }
                        alt='biocharKilnPhoto3'
                      />
                    ) : null}
                  </Box>

                  {/* Unburnt Pieces */}
                  {media?.unburntPiecesPanelPhotoURL ||
                    media?.largestPieceDiameterPhoto ? (
                    <Typography sx={{ my: 2 }} component={'h5'} variant='h5'>
                      Unburnt Pieces
                    </Typography>
                  ) : null}
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '1rem',
                      mb: '1rem',
                    }}
                  >
                    {media?.unburntPiecesPanelPhotoURL ? (
                      <img
                        height='300'
                        width='300'
                        src={
                          UPLOADED_DOCS +
                          media?.unburntPiecesPanelPhotoURL?.split('/').pop()
                        }
                        alt='unburnt pieces'
                      />
                    ) : null}
                    {media?.largestPieceDiameterPhoto ? (
                      <img
                        height='300'
                        width='300'
                        src={
                          UPLOADED_DOCS +
                          media?.largestPieceDiameterPhoto?.split('/').pop()
                        }
                        alt='largest piece diameter'
                      />
                    ) : null}
                  </Box>
                </Box>
              );
            })}
          </Box>
        );
      })}
    </>
  );
};

const mapStateToProps = state => ({
  batchMedia: state.postFormData.batchMedia,
  loading: state.postFormData.loading,
});

const mapDispatchToProps = dispatch => {
  return {
    getBatchMedia: projectKey => dispatch(getBatchMedia(projectKey)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaFeature);
