import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { useDispatch, useSelector } from 'react-redux'
import { getFertilizerPlotlistData, submitFertilizerPlotlistData, getFertilizers } from '../../../../../actions/carbonfootprint';

export default function FertilizerPlotListTable() {

    const dispatch = useDispatch()

    const { loading, fertilizers, fertilizerPlotlistData } = useSelector(state => state.carbonfootprint) // fertilizerAmountAppliedTotals
    const facility = localStorage.getItem('facility')
    const year = localStorage.getItem('year')

    useEffect(() => {
        dispatch(getFertilizerPlotlistData(facility, year))
        dispatch(getFertilizers(facility, year))
    }, [year, facility, dispatch])

    const loadedTableState = fertilizerPlotlistData?.plotlistsWithFertilizers?.reduce((acc, curr) => {
        curr.fertilizer_application_data?.forEach((applicationData) => {
            acc[`application_data_id_${applicationData._id}`] = applicationData._id
            acc[`application_data_id_${applicationData._id}_rate`] = applicationData.fertilizer_application_rate
            acc[`application_data_id_${applicationData._id}_amount_applied`] = applicationData.fertilizer_amount_applied
        })
        return acc
    }, {})

    const [tableState, setTableState] = useState(loadedTableState)

    useEffect(() => {
        setTableState({ ...loadedTableState })
    }, [dispatch, fertilizerPlotlistData, loadedTableState])

    const handleFormDataChange = (id, name, hectares, event) => {
        tableState[name] = event.target.value
        tableState[`application_data_id_${id}_amount_applied`] = (Number(hectares) * Number(event.target.value)).toFixed(2)
        setTableState({
            ...tableState,
        })
    }

    const getTotals = () => {
        return Object.keys(tableState).reduce((acc, key) => {
            const match = key.match(/^application_data_id_(\d+)(?:_(amount_applied|rate))?$/);

            if (match) {
                const id = parseInt(match[1]);
                const prop = key.endsWith("_amount_applied") ? "amount_applied" : "rate";

                if (!acc[id]) {
                    acc[id] = { id };
                }

                acc[id][prop] = tableState[key];
            }

            return acc;

        }, [])
    }

    const handleDataSubmission = () => {
        const result = getTotals()
        const year = localStorage.getItem('year')
        const facility = localStorage.getItem('facility')
        dispatch(submitFertilizerPlotlistData(result.filter((v) => v !== null), facility, year)).then(() => {
            dispatch(getFertilizers(facility, year))
        })
    }

    return (
        <Box sx={{ width: '100%', mt: 3 }}>
            {/* {console.log(fertilizerPlotlistData)} */}
            <TableContainer component={Paper} sx={{ width: '100%' }} >
                {loading ? <h1>Loading</h1> :
                    <Table sx={{ minWidth: '100%', width: '100%' }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="right">Block Name</TableCell>
                                <TableCell align="right">Official Land Number</TableCell>
                                <TableCell align="right">Hectares</TableCell>
                                <TableCell align="right">Primary Crop</TableCell>
                                {fertilizerPlotlistData.plotlistsWithFertilizers ? fertilizerPlotlistData?.plotlistsWithFertilizers[0]?.fertilizers?.map((fertilizer) => {
                                    if (fertilizer.is_active) {
                                        return (
                                            <>
                                                <TableCell align="left">{fertilizer?.product_name} <br /><b style={{ color: "darkblue" }}>Application Rate ({fertilizer.application_rate_unit})</b></TableCell>
                                                <TableCell align="left">Amount Applied </TableCell>
                                            </>
                                        )
                                    } else return null
                                }) : null}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {fertilizerPlotlistData?.plotlistsWithFertilizers?.map((fpd) => (
                                <TableRow
                                    key={fpd.plotlist.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="right">{fpd.plotlist.block_name}</TableCell>
                                    <TableCell align="right">{fpd.plotlist.official_land_num}</TableCell>
                                    <TableCell align="right">{fpd.plotlist.hectares}</TableCell>
                                    <TableCell align="right">{fpd.plotlist.primary_crop}</TableCell>
                                    {fpd?.fertilizer_application_data?.map((applicationData) => {
                                        return (
                                            <>
                                                <TableCell align="right">
                                                    <TextField
                                                        fullWidth
                                                        name={`application_data_id_${applicationData?._id}_rate`}
                                                        label={''}
                                                        value={tableState[`application_data_id_${applicationData._id}_rate`]}
                                                        onChange={(event) => { handleFormDataChange(applicationData?._id, `application_data_id_${applicationData?._id}_rate`, fpd.plotlist.hectares, event) }}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    {(Number(fpd.plotlist.hectares) * Number(tableState[`application_data_id_${applicationData?._id}_rate`])).toFixed(2)}
                                                </TableCell>
                                            </>
                                        )
                                    })
                                    }
                                    {/* <TableCell align="right">
                                    <TextField
                                        fullWidth
                                        name={'applicationRate'}
                                        label={'Application Rate'}
                                        value={''}
                                        onChange={() => { }}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    hectares * application rate
                                </TableCell> */}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>}
            </TableContainer>
            <Box>
                <Button sx={{ m: '1rem 0' }} variant="contained" onClick={handleDataSubmission}>Submit data</Button>
                {fertilizers?.length > 0 ? <h4>Summary</h4> : null}
                <ul>
                    {fertilizers?.length > 0 ? fertilizers?.map((fertilizer) => {
                        return fertilizer.total_applied !== null ?
                            <li>{fertilizer.product_name} total amount applied: {`${String(fertilizer.total_applied.toFixed(2))} ${fertilizer.application_rate_unit === 'kg/ha' ? 'kg' : fertilizer.application_rate_unit === 'L/ha' ? 'L' : ''}`}</li> : null
                    }) : null
                    }
                </ul>
            </Box>
            {/* Summary

            Total Nexus Hesta Al die amount applied gesom in die unit kg of L */}
        </Box>


    );
}
