import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../actions/auth';
import {
  Box,
  Button,
  Typography,
  Grid,
  CircularProgress,
  TextField,
} from '@mui/material';
// import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/IkhalaLogo.svg';
const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  // const navigate = useNavigate();

  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.auth);
  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    try {
      dispatch(login({ username: email, email, password })).then(() => {
        // navigate('/programs')
      });
    } catch (err) {
      // try catch is usede to prevent navigation - error handling done in redux action
    }
  };

  const { email, password } = formData;

  const items = [
    {
      id: 0,
      name: 'email',
      label: 'Email',
      value: email,
    },
    {
      id: 1,
      name: 'password',
      label: 'Password',
      value: password,
    },
  ];

  return (
    <Grid container sx={{ width: '100%', height: '100vh' }}>
      <Grid item xs={6} sx={{ background: "linear-gradient(to bottom, #D7CBFF, #212746)", height: '80%' }}>
        <Box
          component='form'
          onSubmit={e => onSubmit(e)}
          id='box'
          sx={{
            height: 1,
            p: 5,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',


          }}
        >
          {items.map(item => {
            return (
              <Box component='div' sx={{ mt: 2, width: '250px' }} key={item.id}>
                <TextField
                  label={item.label}
                  name={item.name}
                  value={item.value}
                  type={item.name}
                  sx={{ background: '#fff', width: '100%' }}
                  onChange={e => onChange(e)}
                  required
                  fullWidth
                />
              </Box>
            );
          })}
          <Button
            variant='contained'
            size='large'
            sx={{ mt: 2, width: '250px', fontSize: '16px' }}
            type='submit'
            fullWidth
          >
            {loading ? (
              <CircularProgress sx={{ color: '#fff' }} />
            ) : (
              <Typography sx={{}}>Login</Typography>
            )}
          </Button>

          <Button sx={{ width: '50%', color: '#fff' }} href='/register' fullWidth>
            Don't have an account? Register
          </Button>

        </Box>
      </Grid>
      <Grid item xs={6} sx={{ background: '#212746', height: '80%' }}>
        <img src='login.png' width='100%' height='100%' alt='ikhala logo' style={{ borderBottomLeftRadius: '20px' }} />
      </Grid>
      <Box sx={{ background: '#212746', color: '#fff', p: 8, width: '100%', height: '50px', display: 'flex', justifyContent: 'center' }}>
        <img
          src={Logo}
          width={80}
          height={80}
          style={{ padding: '0', margin: '0', opacity: '0.5', marginTop: '20px' }}
          alt='ikhala-logo'
        />
        <Typography sx={{ ml: -2, marginTop: '50px', opacity: '0.5' }}> Ikhala {new Date().getFullYear()}</Typography>
      </Box>
    </Grid >

  );
};

export default Login;
