
import Main from './components/layout/Main';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import { Box, NoSsr, ThemeProvider } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Notification from './components/layout/Notification';
import theme from './theme';
import LandingPage from './components/layout/landing-page/LandingPage';
import Registry from './components/layout/landing-page/Registry';
// import { useSelector } from 'react-redux';
import DashboardLayout from './components/admin/DashboardLayout';
import NotFound from './components/auth/NotFound';
import { useEffect, useState } from 'react';


function App() {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const authStatus = JSON.parse(localStorage.getItem('authenticated'));
    setAuthenticated(authStatus);
    if (window.location.pathname === '/login' || window.location.pathname === '/register' || window.location.pathname === '/') {
      localStorage.removeItem('authenticated');
    }
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      const authStatus = JSON.parse(localStorage.getItem('authenticated'));
      setAuthenticated(authStatus);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);


  return (
    <NoSsr>
      <ThemeProvider theme={theme}>
        <Router>
          <Box>
            <Notification />
            {authenticated ? (
              <Routes>
                <Route exact path='/'>
                  {/* <Route exact path='/' element={<LandingPage />}></Route> */}
                  {/* <Route exact path='/' element={<Main />} /> */}
                  <Route exact path=':page' element={<Main />} />
                  <Route exact path='/project/:projectId' element={<Main />} />
                </Route>
                <Route exact path='/login' element={<Login />} />
                <Route exact path='/register' element={<Register />} />
                <Route exact path='/dashboard'>
                  <Route exact path='' element={<DashboardLayout />} />
                  <Route exact path=':screen' element={<DashboardLayout />} />
                </Route>
                <Route exact path='/' element={<LandingPage />}></Route>
                <Route exact path='/registry' element={<Registry />}></Route>
              </Routes>
            ) : (
              <Routes>
                <Route exact path='/' element={<LandingPage />}></Route>
                <Route exact path='/login' element={<Login />}></Route>
                <Route exact path='/registry' element={<Registry />}></Route>
                <Route exact path='/register' element={<Register />} />
                <Route path='*' element={<NotFound />} />
              </Routes>
            )}
          </Box>
        </Router>
      </ThemeProvider>
    </NoSsr >
  );
}

export default App;
