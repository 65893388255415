import { setAlert } from './alert';
import { API_FAQS } from './constants';
import { GET_FAQ_FAIL, GET_FAQ_SUCCESS } from './types';
import axios from 'axios';

// const token = JSON.parse(localStorage.getItem('token'))?.token;

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
};

// Fetch FAQs
export const getFaqs = () => async dispatch => {
  try {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + API_FAQS,
      config
    );
    dispatch({
      type: GET_FAQ_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: GET_FAQ_FAIL,
    });
  }
};
