import React, { useEffect, useState } from 'react';
import { Alert, Typography, TextField, Grid, Button, Tabs, Tab, TableContainer, Table, TableHead, TableCell, TableRow, Paper, TableBody, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
// import { connect } from 'react-redux';
import { getDataTablesData, labReportFileUpload, postBatchCcik, postBiocharStoredCarbonContent } from '../../../../../actions/forms';
import BF005VerificationRequest from './types/BF005VerificationRequest';
import { getUserDetails } from '../../../../../actions/auth';
import DieselDataCapturingStationary from './types/data-tables/fuel/DieselDataCapturingStationary';
import DieselDataCapturingMobile from './types/data-tables/fuel/DieselDataCapturingMobile';
import PetrolDataCapturingStationary from './types/data-tables/fuel/PetrolDataCapturingStationary';
import { PetrolDataCapturingMobile } from './types/data-tables/fuel/PetrolDataCapturingMobile';
import LPGDataCapturing from './types/data-tables/fuel/LPGDataCapturing';

export const DataTables = ({ dataBF005, setData, setFormId, next, projectId, projectKey, active, measureType }) => {
    // console.log("🚀 ~ DataTables ~ projectKey:", projectKey)

    const batchData = useSelector(state => state.postFormData.dataTablesData.batchdata);
    const batchTotalsAndAverages = useSelector(state => state.postFormData.dataTablesData.totals_and_averages);

    const { userData } = useSelector(state => state.auth);
    const year = new Date().getFullYear()
    const dispatch = useDispatch();
    const [loadedBatchData, setLoadedBatchData] = useState([]);

    useEffect(() => {
        setLoadedBatchData(batchData);
    }, [dispatch, batchData]);

    useEffect(() => {
        dispatch(getUserDetails());
        dispatch(getDataTablesData(projectKey));
    }, [dispatch, projectKey]);

    const items = [
        {
            id: 0,
            name: 'Batch Volumes',
        },
        {
            id: 1,
            name: 'Batch Bulk Density',
        },
        {
            id: 2,
            name: 'Biochar Mass',
        },
        {
            id: 3,
            name: 'Batch Carbon Content',
        },
        {
            id: 4,
            name: 'Biochar Stored Carbon'
        },
    ];

    const dieselItems = [
        {
            id: 0,
            name: 'Stationary',
        },
        {
            id: 1,
            name: 'Mobile',
        }
    ];

    const petrolItems = [
        {
            id: 0,
            name: 'Stationary',
        },
        {
            id: 1,
            name: 'Mobile',
        }
    ];

    const lpgItems = [
        {
            id: 0,
            name: 'Liquid Petroleum Gas',
        }
    ];

    const items3 = [
        {
            id: 0,
            name: 'Data Validation',
        }
    ];

    const [value, setValue] = useState(0);
    const handleChange = (event, index) => {
        setValue(index);
    };
    const [fuelValue, setFuelValue] = useState(0);
    const handleFuelChange = (event, index) => {
        setFuelValue(index);
    };

    const handleCcikInputChange = (e, batch, kiln) => {
        setLoadedBatchData((prevData) => {
            let newData = { ...prevData }; // create a copy of the data
            for (let key in newData) { // iterate over each key in the data
                newData[key] = newData[key].map((data) => { // map over the array at the current key
                    if (data.batch === batch && data.kilnUsed === kiln) {
                        return { ...data, ccik: +e.target.value } // update the ccik value if the batch matches
                    }
                    return { ...data }
                })
            }
            return newData; // return the updated data
        })
        // setConvData((prevRows) => {
        //     return prevRows.map((row) => {
        //         if (row.variable === variable && row.type === type) {
        //             return { ...row, average_value: e.target.value }
        //         }
        //         return { ...row }
        //     })
        // })
    }

    const handleLabResultsFileUpload = (e) => {
        const file = e.target.files[0];
        let formData = new FormData();
        formData.append('file', file);
        dispatch(labReportFileUpload(formData, year, userData.farm[0]._id, projectKey))
    }

    const prdebOptions = [{ id: 0, type: 'ACB (0.8)', value: 0.8 }, { id: 0, type: 'FCK (0.8)', value: 0.8 }, { id: 0, type: 'CB (0.65)', value: 0.65 }]

    const handlePrdebOptionsChange = (e, batch, kiln) => {
        setLoadedBatchData((prevData) => {
            let newData = { ...prevData }; // create a copy of the data
            for (let key in newData) { // iterate over each key in the data
                newData[key] = newData[key].map((data) => { // map over the array at the current key
                    if (data.batch === batch && data.kilnUsed === kiln) {
                        return { ...data, prde: +e.target.value } // update the ccik value if the batch matches
                    }
                    return { ...data }
                })
            }
            return newData; // return the updated data
        })
        // dispatch(postMeasurementType({ measurementType: e.target.value }, projectKey))
    }

    const handleSubmitBatchCarbonContent = () => {
        dispatch(postBatchCcik(loadedBatchData))

    }

    const handleSubmitBiocharStoredCarbon = () => {
        dispatch(postBiocharStoredCarbonContent(loadedBatchData))
    }

    return (
        <Grid container>
            {/* {console.log(batchData)} */}
            <Grid item sx={{ mb: 2, display: 'flex', width: '100%' }}>
                <Tabs value={value} onChange={handleChange} style={{ marginBottom: 10 }}>
                    <Tab label="Biochar" />
                    <Tab label="Fuel" />
                    <Tab label="Data Validation" />
                </Tabs>
            </Grid>
            {value === 0 && (
                <>
                    <Grid item xs={2} sx={{ borderRight: '1px solid', p: 2 }}>
                        {items.map(item => (
                            <Button
                                fullWidth
                                key={item.id}
                                onClick={() => next(item.id)}
                                variant={active === item.id ? 'outlined' : null}
                            >
                                {item.name}
                            </Button>
                        ))}
                    </Grid>
                    {active === 0 && (
                        <Grid item xs={10} sx={{ p: 2 }}>
                            <TableContainer key={0} component={Paper} sx={{ height: 'auto' }}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">
                                                ProjectId
                                            </TableCell>
                                            <TableCell align="left">
                                                BatchId
                                            </TableCell>
                                            <TableCell align="left">
                                                KilnId
                                            </TableCell>
                                            <TableCell align="left">
                                                Gross Volume
                                            </TableCell>
                                            <TableCell align="left">
                                                Unburnt Volume
                                            </TableCell>
                                            <TableCell align="left">
                                                Net Volume
                                            </TableCell>
                                            <TableCell align="left">
                                                Unit
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {batchData && Object.keys(batchData)?.map((key, i, arr) => {
                                            return batchData[key].map((data, j, subArr) => {
                                                const isLastIteration = i === arr.length - 1 && j === subArr.length - 1;
                                                return (
                                                    <TableRow key={i}>
                                                        <TableCell align="left">{data.projectId}</TableCell>
                                                        <TableCell align="left">{key}</TableCell>
                                                        <TableCell align="left" sx={isLastIteration ? { borderBottom: '2px solid black' } : null}>{data.kilnUsed}</TableCell>
                                                        <TableCell align="left" sx={isLastIteration ? { borderBottom: '2px solid black' } : null}>{data.grossVol}</TableCell>
                                                        <TableCell align="left" sx={isLastIteration ? { borderBottom: '2px solid black' } : null}>{data.unburntVol}</TableCell>
                                                        <TableCell align="left" sx={isLastIteration ? { borderBottom: '2px solid black' } : null}>{data.netVol}</TableCell>
                                                        <TableCell align="left">{measureType === 'Imperial' ? <> yrd<sup>3</sup></> : <> m<sup>3</sup></>}</TableCell>
                                                    </TableRow>
                                                );
                                            });
                                        })}
                                        <TableRow>
                                            <TableCell align="left"></TableCell>
                                            <TableCell align="left"></TableCell>
                                            <TableCell align="left" sx={{ border: '2px solid black' }}><b>TOTAL</b></TableCell>
                                            <TableCell align="left" sx={{ border: '2px solid black' }}><b>{batchTotalsAndAverages?.batch_volumes?.grossVol}</b></TableCell>
                                            <TableCell align="left" sx={{ border: '2px solid black' }}><b>{batchTotalsAndAverages?.batch_volumes?.unburntVol}</b></TableCell>
                                            <TableCell align="left" sx={{ border: '2px solid black' }}><b>{batchTotalsAndAverages?.batch_volumes?.netVol}</b></TableCell>
                                            <TableCell align="left">{measureType === 'Imperial' ? <> yrd<sup>3</sup></> : <> m<sup>3</sup></>}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    )}
                    {active === 1 && (

                        <Grid item xs={10} sx={{ p: 2 }}>

                            <TableContainer key={0} component={Paper} sx={{ height: 'auto', mt: 3 }}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">
                                                ProjectId
                                            </TableCell>
                                            <TableCell align="left">
                                                BatchId
                                            </TableCell>
                                            <TableCell align="left">
                                                KilnId
                                            </TableCell>
                                            <TableCell align="left">
                                                Empty Bucket Weight
                                            </TableCell>
                                            <TableCell align="left">
                                                Hot Coals Bucket Weight
                                            </TableCell>
                                            <TableCell align="left">
                                                Bucket Volume
                                            </TableCell>
                                            <TableCell align="left">
                                                Bulk Density
                                            </TableCell>
                                            <TableCell align="left">
                                                Unit
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {batchData && Object.keys(batchData)?.map((key, i, arr) => {
                                            return batchData[key].map((data, j, subArr) => {
                                                const isLastIteration = i === arr.length - 1 && j === subArr.length - 1;
                                                return (
                                                    <TableRow key={i}>
                                                        <TableCell align="left">
                                                            {data.projectId}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {key}
                                                        </TableCell>
                                                        <TableCell align="left" sx={isLastIteration ? { borderBottom: '2px solid black' } : null}>
                                                            {data.kilnUsed}
                                                        </TableCell>
                                                        <TableCell align="left" sx={isLastIteration ? { borderBottom: '2px solid black' } : null}>
                                                            {data.additionalData?.emptyBucketWeight}
                                                        </TableCell>
                                                        <TableCell align="left" sx={isLastIteration ? { borderBottom: '2px solid black' } : null}>
                                                            {data.additionalData?.hotCoalsWeight}
                                                        </TableCell>
                                                        <TableCell align="left" sx={isLastIteration ? { borderBottom: '2px solid black' } : null}>
                                                            {data.additionalData?.bulkBucketSize}
                                                        </TableCell>
                                                        <TableCell align="left" sx={isLastIteration ? { borderBottom: '2px solid black' } : null}>
                                                            {data.additionalData?.bulkDensity}
                                                        </TableCell>
                                                        <TableCell align="left" sx={isLastIteration ? { borderBottom: '2px solid black' } : null}>
                                                            {measureType === 'Imperial' ? <> lbs/yrd<sup>3</sup></> : <> kg/m<sup>3</sup></>}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        })}
                                        <TableRow>
                                            <TableCell align="left"></TableCell>
                                            <TableCell align="left"></TableCell>
                                            <TableCell align="left" sx={{ border: '2px solid black' }}><b>AVERAGE</b></TableCell>
                                            <TableCell align="left" sx={{ border: '2px solid black' }}></TableCell>
                                            <TableCell align="left" sx={{ border: '2px solid black' }}></TableCell>
                                            <TableCell align="left" sx={{ border: '2px solid black' }}></TableCell>
                                            <TableCell align="left" sx={{ border: '2px solid black' }}>{batchTotalsAndAverages.batch_bulk_density?.bulkDensity}</TableCell>
                                            <TableCell align="left" sx={{ border: '2px solid black' }}>{measureType === 'Imperial' ? <> lbs/yrd<sup>3</sup></> : <> kg/m<sup>3</sup></>}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    )}
                    {active === 2 && (
                        <Grid item xs={10} sx={{ p: 2 }}>
                            <TableContainer key={0} component={Paper} sx={{ height: 'auto', mt: 3 }}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">
                                                ProjectId
                                            </TableCell>
                                            <TableCell align="left">
                                                BatchId
                                            </TableCell>
                                            <TableCell align="left">
                                                KilnId
                                            </TableCell>
                                            <TableCell align="left">
                                                Net Vol {measureType === 'Imperial' ? <> (yrd<sup>3</sup>)</> : <> (m<sup>3</sup>)</>}
                                            </TableCell>
                                            <TableCell align="left">
                                                BD {measureType === 'Imperial' ? <> (lbs/yrd<sup>3</sup>)</> : <> (kg/m<sup>3</sup>)</>}
                                            </TableCell>
                                            <TableCell align="left">
                                                Biochar Mass {measureType === 'Imperial' ? <>(lbs)</> : <>(kg)</>}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {batchData && Object.keys(batchData)?.map((key, i, arr) => {
                                            return batchData[key].map((data, j, subArr) => {
                                                const isLastIteration = i === arr.length - 1 && j === subArr.length - 1;
                                                return (
                                                    <TableRow key={i}>
                                                        <TableCell align="left">
                                                            {data.projectId}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {key}
                                                        </TableCell>
                                                        <TableCell align="left" sx={isLastIteration ? { borderBottom: '2px solid black' } : null}>
                                                            {data.kilnUsed}
                                                        </TableCell>
                                                        <TableCell align="left" sx={isLastIteration ? { borderBottom: '2px solid black' } : null}>
                                                            {data.netVol}
                                                        </TableCell>
                                                        <TableCell align="left" sx={isLastIteration ? { borderBottom: '2px solid black' } : null}>
                                                            {data.additionalData?.bulkDensity}
                                                        </TableCell>
                                                        <TableCell align="left" sx={isLastIteration ? { borderBottom: '2px solid black' } : null}>
                                                            {data.additionalData?.biocharMass}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        })}
                                        <TableRow>
                                            <TableCell align="left"></TableCell>
                                            <TableCell align="left"></TableCell>
                                            <TableCell align="left" sx={{ border: '2px solid black' }}><b>TOTAL</b></TableCell>
                                            <TableCell align="left" sx={{ border: '2px solid black' }}><b>{batchTotalsAndAverages.biochar_mass?.totalNetVol}</b></TableCell>
                                            <TableCell align="left" sx={{ border: '2px solid black' }}></TableCell>
                                            <TableCell align="left" sx={{ border: '2px solid black' }}><b>{batchTotalsAndAverages.biochar_mass?.totalBiocharMass}</b></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="left"></TableCell>
                                            <TableCell align="left"></TableCell>
                                            <TableCell align="left" sx={{ border: '2px solid black' }}><b>AVERAGE</b></TableCell>
                                            <TableCell align="left" sx={{ border: '2px solid black' }}><b>{batchTotalsAndAverages.biochar_mass?.averageNetVol}</b></TableCell>
                                            <TableCell align="left" sx={{ border: '2px solid black' }}><b>{batchTotalsAndAverages.biochar_mass?.averageBulkDensity}</b></TableCell>
                                            <TableCell align="left" sx={{ border: '2px solid black' }}><b>{batchTotalsAndAverages.biochar_mass?.averageBiocharMass}</b></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    )}
                    {active === 3 && (
                        <Grid item xs={10} sx={{ p: 2 }}>

                            <TableContainer key={0} component={Paper} sx={{ height: 'auto', mt: 3 }}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">
                                                ProjectId
                                            </TableCell>
                                            <TableCell align="left">
                                                BatchId
                                            </TableCell>
                                            <TableCell align="left">
                                                KilnId
                                            </TableCell>
                                            <TableCell align="left">
                                                CCi, k (%)
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {loadedBatchData && Object.keys(loadedBatchData)?.map((key, i) => {
                                            return loadedBatchData[key].map((data) => {
                                                // const isLastIteration = i === arr.length - 1 && j === subArr.length - 1;
                                                return (
                                                    <TableRow key={i}>
                                                        <TableCell align="left">
                                                            {data.projectId}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {key}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {data.kilnUsed}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TextField
                                                                required={true}
                                                                fullWidth
                                                                name={'fcptb'}
                                                                // value={!!inlandData.average_value ? inlandData.average_value : ''}
                                                                value={data?.ccik ? data.ccik : null}
                                                                onChange={(e) => handleCcikInputChange(e, data.batch, data.kilnUsed)}
                                                                type="number"
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Button variant="contained" sx={{ m: 2 }} onClick={handleSubmitBatchCarbonContent}>Submit Batch Carbon Content</Button>
                            <Typography variant='h6' component='p' sx={{ mt: 2 }}>
                                Please upload lab results:
                            </Typography>
                            <TextField
                                name={'Lab results'}
                                // value={!!r?.file?.name ? r.file.name : !!r?.file ? r.file : ''}
                                onChange={(e) => handleLabResultsFileUpload(e)}
                                type='file'
                            />
                        </Grid>)}
                    {active === 4 && (
                        <Grid item xs={10} sx={{ p: 2 }}>

                            <TableContainer key={0} component={Paper} sx={{ height: 'auto', mt: 3 }}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">
                                                BM,i,k
                                            </TableCell>
                                            <TableCell align="left">
                                                Cc,i,k
                                            </TableCell>
                                            <TableCell align="left">
                                                BSC (batch total)
                                            </TableCell>
                                            <TableCell align="left">
                                                PRde,b
                                            </TableCell>
                                            <TableCell align="left">
                                                BSC (100y permanence)
                                            </TableCell>
                                            <TableCell align="left">
                                                Unit
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {loadedBatchData && Object.keys(loadedBatchData)?.map((key, i) => {
                                            return loadedBatchData[key].map((data) => {
                                                // const isLastIteration = i === arr.length - 1 && j === subArr.length - 1;
                                                return (
                                                    <TableRow key={i}>
                                                        <TableCell align="left">
                                                            {data.additionalData?.biocharMass}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {data.ccik ? data.ccik : <Typography sx={{ color: 'darkorange' }}>CC,i,k not defined</Typography>}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {data.ccik && measureType === 'Imperial' ? (data.additionalData?.biocharMass * (data.ccik / 100) * 3.666666667 * 0.453592).toFixed(2) :
                                                                data.ccik && measureType === 'Metric' ? (data.additionalData?.biocharMass * (data.ccik / 100) * 3.666666667).toFixed(2) :
                                                                    <Typography sx={{ color: 'darkorange' }}>CC,i,k not defined</Typography>}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <FormControl
                                                                sx={{
                                                                    width: '175px'
                                                                }}

                                                            >
                                                                <InputLabel sx={{ background: 'white', pl: 1, pr: 1 }}>
                                                                    Select unit type
                                                                </InputLabel>
                                                                <Select
                                                                    name='prdebOptions'
                                                                    value={data?.prde ? data.prde : null}
                                                                    onChange={(e) => handlePrdebOptionsChange(e, data.batch, data.kilnUsed)}
                                                                    disabled={data.ccik ? false : true}
                                                                >
                                                                    {prdebOptions.map(option => {
                                                                        return (
                                                                            <MenuItem key={option.id} value={option.value}>
                                                                                {option.type}
                                                                            </MenuItem>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {data.prde && measureType === 'Imperial' ? (data.additionalData?.biocharMass * (data.ccik / 100) * data.prde * 3.666666667 * 0.453592).toFixed(2) :
                                                                data.prde && measureType === 'Metric' ? (data.additionalData?.biocharMass * (data.ccik / 100) * data.prde * 3.666666667).toFixed(2) :
                                                                    <Typography sx={{ color: 'darkorange' }}>PRde,b not defined</Typography>}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            kg CO2e
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Alert severity='warning'>Please submit data if PRde,b is adjusted</Alert>
                            <Button variant="contained" sx={{ m: 2 }} onClick={handleSubmitBiocharStoredCarbon}>Submit Biochar Stored Carbon</Button>
                        </Grid>)}
                </>
            )}
            {value === 1 && (
                <>
                    <Grid item sx={{ mb: 2, display: 'flex', width: '100%' }}>
                        <Tabs value={fuelValue} onChange={handleFuelChange} style={{ marginBottom: 10 }}>
                            <Tab label="Diesel" />
                            <Tab label="Petrol" />
                            <Tab label="Liquid Petroleum Gas" />
                        </Tabs>
                    </Grid>
                    <Grid item xs={2} sx={{ borderRight: '1px solid', p: 2 }}>
                        {fuelValue === 0 ? dieselItems.map(item => (
                            <Button
                                fullWidth
                                key={item.id}
                                onClick={() => next(item.id)}
                                variant={active === item.id ? 'outlined' : null}
                            >
                                {item.name}
                            </Button>
                        )) : fuelValue === 1 ? petrolItems.map(item => (
                            <Button
                                fullWidth
                                key={item.id}
                                onClick={() => next(item.id)}
                                variant={active === item.id ? 'outlined' : null}
                            >
                                {item.name}
                            </Button>)) : lpgItems.map(item => (
                                <Button
                                    fullWidth
                                    key={item.id}
                                    onClick={() => next(item.id)}
                                    variant={active === item.id ? 'outlined' : null}
                                >
                                    {item.name}
                                </Button>))}
                    </Grid>
                    <Grid item xs={10} sx={{ p: 2 }}>
                        {/* {fuelValue === 0 && active === 0 ? <DieselDataCapturingStationary /> : fuelValue === 0 && active === 1 ? <DieselDataCapturingMobile /> : null} */}
                        {fuelValue === 0 && active === 0 ? <DieselDataCapturingStationary farm={userData.farm[0]._id} project={projectKey} /> : fuelValue === 0 && active === 1 ? <DieselDataCapturingMobile farm={userData.farm[0]._id} project={projectKey} /> : null}
                        {fuelValue === 1 && active === 0 ? <PetrolDataCapturingStationary farm={userData.farm[0]._id} project={projectKey} /> : fuelValue === 1 && active === 1 ? <PetrolDataCapturingMobile farm={userData.farm[0]._id} project={projectKey} /> : null}
                        {fuelValue === 2 && active === 0 ? <LPGDataCapturing farm={userData.farm[0]._id} project={projectKey} /> : null}
                    </Grid>
                </>
            )}
            {value === 2 && (
                <>
                    <Grid item xs={2} sx={{ borderRight: '1px solid', p: 2 }}>
                        {items3.map(item => (
                            <Button
                                fullWidth
                                key={item.id}
                                onClick={() => next(item.id)}
                                variant={active === item.id ? 'outlined' : null}
                            >
                                {item.name}
                            </Button>
                        ))}
                    </Grid>
                    <Grid item xs={10} sx={{ p: 2 }}>
                        <BF005VerificationRequest
                            data={dataBF005}
                            setData={setData}
                            setFormId={setFormId}
                            next={next}
                            projectId={projectId}
                            projectKey={projectKey}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    )
}
