import { connect } from 'react-redux';
import { getBatchMedia, getBatchData } from '../../actions/forms';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
    Box,
    Button,
    TableContainer,
    Paper,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Table,
    Typography,
    Divider
} from '@mui/material';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { UPLOADED_DOCS } from '../../actions/constants';

const ProjectQuickLink = ({ batchMedia, batchData }) => {
    const dispatch = useDispatch();
    let { projectId } = useParams();
    const [searchParams] = useSearchParams();
    // const nickname = searchParams.get("nickname")
    const projectIdentifier = searchParams.get("projectIdentifier")
    const navigate = useNavigate()
    // console.log("🚀 ~ ProjectQuickLink ~ projectIdentifier:", projectIdentifier)

    useEffect(() => {
        dispatch(getBatchMedia(projectId));
    }, [dispatch, projectId]);

    useEffect(() => {
        dispatch(getBatchData(projectId));
    }, [dispatch, projectId]);

    const handleNavigate = () => {
        navigate('/programs', { replace: true, state: { projectId: projectIdentifier, projectKey: projectId } })
    }

    return (
        <>
            <Typography variant="h5" component="h5" sx={{ mb: 3 }}>
                Quick Project Access
            </Typography>
            <Button
                variant='outlined'
                size='small'
                sx={{ mt: 1 }}
                onClick={handleNavigate}
            >
                Go to Project
            </Button>
            <Divider sx={{ mt: 3, mb: 3 }} />
            <Typography variant="h5" component="h5" sx={{ mt: 3, mb: 3 }}>
                Batch Data
            </Typography>
            {
                Object.keys(batchData)?.map((key) => {
                    return (
                        <Box key={key}>
                            <h3>Batch: {key}</h3>
                            <TableContainer component={Paper} sx={{ height: 'auto' }}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">
                                                Gross Volume
                                            </TableCell>
                                            <TableCell align="left">
                                                Unburnt Volume
                                            </TableCell>
                                            <TableCell align="left">
                                                Net Volume
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {batchData[key].map((bd, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell align="left">
                                                        {bd?.grossVol ? <>{bd.grossVol}{bd.units === 'foot' ? <> ft<sup>3</sup></> : <> m<sup>3</sup></>}</> : null}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {bd?.unburntVol ? <>{bd.unburntVol}{bd.units === 'foot' ? <> ft<sup>3</sup></> : <> m<sup>3</sup></>}</> : 0}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {bd?.netVol ? <>{bd.netVol}{bd.units === 'foot' ? <> ft<sup>3</sup></> : <> m<sup>3</sup></>}</> : null}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>

                            </TableContainer>
                        </Box>
                    )
                })}
            <Divider sx={{ mt: 3, mb: 3 }} />
            <Typography variant="h5" component="h5" sx={{ mt: 3, mb: 3 }}>
                Batch Media
            </Typography>
            {
                Object.keys(batchMedia)?.map((key) => {
                    return (
                        <Box key={key}>
                            <h3>Batch: {key}</h3>
                            {batchMedia[key].map((media, index) => {
                                return (
                                    <Box key={index} sx={{ display: 'flex', flexWrap: 'wrap', gap: '1rem', mb: '1rem' }}>
                                        {media?.photoURL ? <img height="300" width="300" src={`${UPLOADED_DOCS}${media?.photoURL?.split("/").pop()}`} alt="biochar kiln" /> : null}
                                        {media?.kilnPhoto ? <img height="300" width="300" src={`${UPLOADED_DOCS}${media?.kilnPhoto?.split("/").pop()}`} alt="biochar kiln" /> : null}
                                        {media?.biocharKilnPhoto ? <img height="300" width="300" src={`${UPLOADED_DOCS}${media?.biocharKilnPhoto?.split("/").pop()}`} alt="biochar kiln" /> : null}
                                        {media?.burnStartPhoto ? <img height="300" width="300" src={`${UPLOADED_DOCS}${media?.burnStartPhoto?.split("/").pop()}`} alt="biochar kiln" /> : null}
                                        {media?.temperatureMeasurePhoto ? <img height="300" width="300" src={`${UPLOADED_DOCS}${media?.temperatureMeasurePhoto?.split("/").pop()}`} alt="biochar kiln" /> : null}
                                        {media?.unburntPiecesPanelPhotoURL ? <img height="300" width="300" src={`${UPLOADED_DOCS}${media?.unburntPiecesPanelPhotoURL?.split("/").pop()}`} alt="biochar kiln" /> : null}
                                        {media?.unburntPiecesPanelPhoto ? <img height="300" width="300" src={`${UPLOADED_DOCS}${media?.unburntPiecesPanelPhoto?.split("/").pop()}`} alt="unburnt pieces" /> : null}
                                        {media?.largestPieceDiameterPhoto ? <img height="300" width="300" src={`${UPLOADED_DOCS}${media?.largestPieceDiameterPhoto?.split("/").pop()}`} alt="biochar kiln" /> : null}
                                        {media?.bucketWeightPhoto ? <img height="300" width="300" src={`${UPLOADED_DOCS}${media?.bucketWeightPhoto?.split("/").pop()}`} alt="bucket weight" /> : null}
                                    </Box>
                                )
                            })}
                        </Box>
                    )
                })}
        </>
    )
}

const mapStateToProps = state => ({
    batchMedia: state.postFormData.batchMedia,
    batchData: state.postFormData.batchData,
    loading: state.postFormData.loading,
});

const mapDispatchToProps = dispatch => {
    return {
        getBatchMedia: projectKey => dispatch(getBatchMedia(projectKey)),
        getBatchData: projectKey => dispatch(getBatchData(projectKey))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectQuickLink);