import React, { useEffect, useState } from 'react';
import { Grid, Tabs, Tab } from '@mui/material';
import Implementation from './Implementation';
import Charging from './types/Charging';
import Application from './types/Application';
import FieldNotes from './types/FieldNotes';

export const DataMonitoring = ({
  active,
  next,
  totalItems,
  projectId,
  projectKey,
  measureType,
}) => {
  const items = [
    {
      id: 0,
      name: 'Batch Data',
    },
    {
      id: 1,
      name: 'Batch Media',
    },
    {
      id: 2,
      name: 'Raw Data',
    },
  ];

  useEffect(() => {
    totalItems(items.length);
  }, [items.length, totalItems]);

  const [value, setValue] = useState(0);
  const handleChange = (event, index) => {
    setValue(index);
  };

  const renderTabContent = tab => {
    switch (tab) {
      case 1:
        return <Charging projectKey={projectKey} active={active} next={next} />;
      case 2:
        return (
          <Application projectKey={projectKey} active={active} next={next} />
        );
      case 3:
        return <FieldNotes projectId={projectKey} />;
      default:
        return (
          <Implementation
            projectId={projectId}
            active={active}
            next={next}
            totalItems={totalItems}
            projectKey={projectKey}
            measureType={measureType}
          />
        );
    }
  };

  return (
    <Grid container>
      <Grid item sx={{ mb: 2, display: 'flex', width: '100%' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          style={{ marginBottom: 10 }}
        >
          <Tab label='Production' />
          <Tab label='Charging' />
          <Tab label='Application' />
          <Tab label='Field Notes' />
        </Tabs>
      </Grid>
      {renderTabContent(value)}
    </Grid>
  );
};
