import { connect } from 'react-redux';
import { getBatchData } from '../../../../../../actions/forms';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
    Box, Table, TableBody, TableContainer, TableRow, TableHead, Paper, TableCell
} from '@mui/material';
const BatchData = ({ projectKey, batchData, measureType }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getBatchData(projectKey));
    }, [dispatch, projectKey]);

    return (
        <>
            {
                Object.keys(batchData)?.map((key) => {
                    return (
                        <Box key={key}>
                            <h3>Batch: {key}</h3>
                            <TableContainer component={Paper} sx={{ height: 'auto' }}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">
                                                Kiln Id
                                            </TableCell>
                                            <TableCell align="left">
                                                Gross Volume
                                            </TableCell>
                                            <TableCell align="left">
                                                Unburnt Volume
                                            </TableCell>
                                            <TableCell align="left">
                                                Net Volume
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {batchData[key].map((bd, index) => {
                                            return (
                                                <TableRow key={index}>

                                                    <TableCell align="left">
                                                        {bd?.kilnUsed}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {bd?.grossVol ? <>{bd.grossVol}{measureType === 'Imperial' ? <> ft<sup>3</sup></> : <> m<sup>3</sup></>}</> : ""}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {bd?.unburntVol ? <>{bd.unburntVol}{measureType === 'Imperial' ? <> ft<sup>3</sup></> : <> m<sup>3</sup></>}</> : 0}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {bd?.netVol ? <>{bd.netVol}{measureType === 'Imperial' ? <> ft<sup>3</sup></> : <> m<sup>3</sup></>}</> : ""}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>

                            </TableContainer>
                            <h4>Additional Batch Data:</h4>
                            <TableContainer component={Paper} sx={{ height: 'auto', mt: '2em' }}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">
                                                Kiln Id
                                            </TableCell>
                                            <TableCell align="left">
                                                Batch Location
                                            </TableCell>
                                            <TableCell align="left">
                                                Feedstock Type
                                            </TableCell>
                                            <TableCell align="left">
                                                Feedstock Moisture
                                            </TableCell>
                                            <TableCell align="left">
                                                Empty Bucket Volume
                                            </TableCell>
                                            <TableCell align="left">
                                                Hot Char & Bucket Weight
                                            </TableCell>
                                            <TableCell align="left">
                                                Burn Start Timestamp
                                            </TableCell>
                                            <TableCell align="left">
                                                Burn Supervisor
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {batchData[key].map((bd, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell align="left">
                                                        {bd?.kilnUsed}
                                                    </TableCell>
                                                    {/* <TableCell align="left">
                                                        {bd?.grossVol ? <>{bd.grossVol}{bd.units === 'foot' ? <> ft<sup>3</sup></> : <> m<sup>3</sup></>}</> : null}
                                                    </TableCell> */}
                                                    <TableCell align="left">
                                                        {bd?.additionalData?.latitude ? <>{bd.additionalData.latitude}/{bd.additionalData.longitude}</> : ""}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {bd?.additionalData?.feedstockType ? <>{bd.additionalData.feedstockType}</> : ""}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {bd?.additionalData?.moistureMeasurement ? <>{bd.additionalData.moistureMeasurement}</> : ""}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {bd?.additionalData?.emptyBucketWeight ? <>{bd.additionalData.emptyBucketWeight}</> : null}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {bd?.additionalData?.hotCoalsWeight ? <>{bd.additionalData.hotCoalsWeight}</> : null}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {bd?.additionalData?.submissionTimestamp ? <>{bd.additionalData.submissionTimestamp}</> : null}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {bd?.additionalData?.supervisorName ? <>{bd.additionalData.supervisorName}</> : null}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>

                            </TableContainer>
                        </Box>
                    )
                })}
        </>
    )
}

const mapStateToProps = state => ({
    batchData: state.postFormData.batchData,
    loading: state.postFormData.loading,
});

const mapDispatchToProps = dispatch => {
    return {
        getBatchData: projectKey => dispatch(getBatchData(projectKey))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchData);