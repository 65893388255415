import { setAlert } from '../../alert';

import {
    GET_WINE_AND_GRAPES_DATA_SUCCESS,
    GET_WINE_AND_GRAPES_DATA_FAIL,
    POST_WINE_AND_GRAPES_DATA_SUCCESS,
    POST_WINE_AND_GRAPES_DATA_FAIL,
    GET_WINE_AND_GRAPES_DATA_TOTALS_SUCCESS,
    GET_WINE_AND_GRAPES_DATA_TOTALS_FAIL,
} from '../types/04-indirect-ghg-emissions-from-products-used-by-an-organisation';

import {
    API_GET_WINE_AND_GRAPES,
    API_POST_WINE_AND_GRAPES,
    API_GET_WINE_AND_GRAPES_TOTALS,
} from '../constants/04-indirect-ghg-emissions-from-products-used-by-an-organisation';

import axios from 'axios';

const config = {
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
};

export const postWineAndGrapesData = (formData, year, company, group, facility) => async dispatch => {
    try {
        const { data } = await axios.post(
            process.env.REACT_APP_API_URL + API_POST_WINE_AND_GRAPES + `/${year}/${company}/${group}/${facility}`,
            formData,
            config
        );
        dispatch({
            type: POST_WINE_AND_GRAPES_DATA_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: POST_WINE_AND_GRAPES_DATA_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getWineAndGrapesData = (year, facility) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_WINE_AND_GRAPES + `/${year}/${facility}`,
            config
        );
        dispatch({
            type: GET_WINE_AND_GRAPES_DATA_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_WINE_AND_GRAPES_DATA_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getWineAndGrapesDataTotals = (year, facility) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_WINE_AND_GRAPES_TOTALS + `/${year}/${facility}`,
            config
        );
        dispatch({
            type: GET_WINE_AND_GRAPES_DATA_TOTALS_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_WINE_AND_GRAPES_DATA_TOTALS_FAIL,
            payload: err.response.data.detail,
        });
    }
};