import { useEffect } from 'react';
import {
  Alert,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Divider,
} from '@mui/material';
import tooltips from '../../../../../../store/tooltips';
import InfoIcon from '@mui/icons-material/Info';
import { getKilnDimensions } from '../../../../../../actions/forms';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';

function BF003aLogKiln({
  data,
  setData,
  setFormId,
  kilnDimensions,
  projectKey,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    setFormId('BF003a');
  }, [setFormId]);

  useEffect(() => {
    dispatch(getKilnDimensions(projectKey));
  }, [dispatch, projectKey]);

  const {
    kilnType,
    kilnId,
    kiln_picture,
    units,
    diameter,
    heightOfInnerPanels,
    numberOfPanels,
    heatShieldPanelWidth,
    heatShieldPanelLength,
    innerWidth,
    innerLength,
    innerHeight,
    height
  } = data;

  const kilns = [
    {
      id: 0,
      name: 'Ring of Fire',
    },
    {
      id: 1,
      name: 'Pit Kiln',
    },
    {
      id: 2,
      name: 'Big Box',
    },
    {
      id: 3,
      name: 'Air Curtain Burner: BurnBoss',
    },
    {
      id: 4,
      name: 'Air Curtain Burner: CharBoss',
    },
    {
      id: 5,
      name: 'Conservation Burn - Hand Pile',
    },
    {
      id: 6,
      name: 'Conservation Burn - Machine Pile',
    },
  ];

  const ringOfFireTableColumns = [
    'Id',
    'Created At',
    'Kiln Type',
    'Kiln Id',
    'Unit',
    'Rof Diameter',
    'Rof Panel Height',
    'Rof Panel Width',
    'Rof Panel Length',
    'Kiln Floor Area',
    'Kiln Empty Volume',
    'Panel Area',
  ];
  const otherKilnTypeColumns = [
    'Id',
    'Created At',
    'Kiln Type',
    'Kiln Id',
    'Unit',
    'Inner Width',
    'Inner Length',
    'Inner Height',
    'Kiln Floor Area',
    'Kiln Empty Volume',
    'Panel Area',
  ];

  const conservationBurnColumns = [
    'Id',
    'Created At',
    'Burn Type',
    'CB Id',
    'Diameter',
    'Height'
  ];
  return (
    <>
      {/* {console.log(kilnDimensions)} */}
      <Box sx={{ display: 'flex' }}>
        <Typography variant='h4' sx={{ mb: 2 }}>
          Log Kiln
        </Typography>
        <Tooltip
          title={<h2 style={{ color: 'lightgreen' }}>{tooltips.logKilnQ1}</h2>}
        >
          <InfoIcon sx={{ width: 15 }} />
        </Tooltip>
      </Box>
      <InputLabel sx={{ background: 'white', pl: 1, pr: 1 }}>
        Please specify the units
      </InputLabel>
      <RadioGroup row name='units' onChange={e => setData(e)}>
        <FormControlLabel
          value='meter'
          control={<Radio />}
          label='Meters'
          name='units'
          checked={units === 'meter'}
        />
        <FormControlLabel
          value='foot'
          control={<Radio />}
          label='Feet'
          name='units'
          checked={units === 'foot'}
        />
      </RadioGroup>
      <FormControl
        sx={{
          mt: 1,
        }}
        fullWidth
      >
        <InputLabel sx={{ background: 'white', pl: 1, pr: 1 }}>
          Select type of kiln
        </InputLabel>
        <Select name='kilnType' value={kilnType} onChange={setData}>
          {kilns.map(kiln => {
            return (
              <MenuItem key={kiln.id} value={kiln.name}>
                {kiln.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {(kilnType !== 'Conservation Burn - Hand Pile' || kilnType === 'Conservation Burn - Machine Pile') && (
        <Typography variant='h6' component='p' sx={{ mt: 2 }}>
          Specify the kiln dimensions
          <Tooltip
            title={
              <h2 style={{ color: 'lightgreen' }}>{tooltips.logKilnQ2}</h2>
            }
          >
            {<InfoIcon sx={{ width: 15 }} />}
          </Tooltip>
        </Typography>
      )}
      {kilnType === 'Ring of Fire' ? (
        <>
          <Typography variant='h6' component='p' sx={{ mt: 2 }}>
            Diameter between inner panels
          </Typography>
          <TextField
            name='diameter'
            value={diameter}
            label='Specify the kiln diameter between inner panels'
            onChange={setData}
            fullWidth
          />
          <Typography variant='h6' component='p' sx={{ mt: 2 }}>
            Height of inner panels
          </Typography>
          <TextField
            name='heightOfInnerPanels'
            value={heightOfInnerPanels}
            label='Height Of Inner Panels'
            onChange={setData}
            fullWidth
          />
          <Typography variant='h6' component='p' sx={{ mt: 2 }}>
            Number Of Panels
          </Typography>
          <TextField
            name='numberOfPanels'
            value={numberOfPanels}
            label='Number Of Panels'
            onChange={setData}
            fullWidth
          />
          <Typography variant='h6' component='p' sx={{ mt: 2 }}>
            Heat Shield Panel Width
          </Typography>
          <TextField
            name='heatShieldPanelWidth'
            value={heatShieldPanelWidth}
            label='Heat Shield Panel Width'
            onChange={setData}
            fullWidth
          />
          <Typography variant='h6' component='p' sx={{ mt: 2 }}>
            Heat Shield Panel Length
          </Typography>
          <TextField
            name='heatShieldPanelLength'
            value={heatShieldPanelLength}
            label='Heat Shield Panel Length'
            onChange={setData}
            fullWidth
          />
        </>
      ) : kilnType === 'Conservation Burn - Hand Pile' || kilnType === 'Conservation Burn - Machine Pile' ?
        <>
          <Typography variant='h6' component='p' sx={{ mt: 2 }}>
            What is the prescribed diameter of your conservation burn pile construction
          </Typography>
          <TextField
            name='diameter'
            value={diameter}
            label='Diameter'
            onChange={setData}
            fullWidth
          />
          <Typography variant='h6' component='p' sx={{ mt: 2 }}>
            What is the prescribed height of your conservation burn
          </Typography>
          <TextField
            name='height'
            value={height}
            label='Height'
            onChange={setData}
            fullWidth
          />
        </> : (
          <>
            <Typography variant='h6' component='p' sx={{ mt: 2 }}>
              Inner Width
            </Typography>
            <TextField
              name='innerWidth'
              value={innerWidth}
              label='Inner Width'
              onChange={setData}
              fullWidth
            />
            <Typography variant='h6' component='p' sx={{ mt: 2 }}>
              Inner Length
            </Typography>
            <TextField
              name='innerLength'
              value={innerLength}
              label='Inner Length'
              onChange={setData}
              fullWidth
            />
            <Typography variant='h6' component='p' sx={{ mt: 2 }}>
              Inner Height
            </Typography>
            <TextField
              name='innerHeight'
              value={innerHeight}
              label='Inner Height'
              onChange={setData}
              fullWidth
            />
          </>
        )}
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        {kilnType === 'Conservation Burn - Hand Pile' || kilnType === 'Conservation Burn - Machine Pile' ? 'CbId' : 'KilnId'}
        <Tooltip
          title={<h2 style={{ color: 'lightgreen' }}>{tooltips.logKilnQ3}</h2>}
        >
          {<InfoIcon sx={{ width: 15 }} />}
        </Tooltip>
      </Typography>
      <TextField
        name='kilnId'
        value={kilnId}
        label={kilnType === 'Conservation Burn - Hand Pile' || kilnType === 'Conservation Burn - Machine Pile' ? 'CbId' : 'KilnId'}
        onChange={setData}
        fullWidth
      />
      <Alert severity='info'>
        {`if your ${kilnType === 'Conservation Burn - Hand Pile' || kilnType === 'Conservation Burn - Machine Pile' ? 'conservation burn' : 'kiln'} does not have an ID yet, you can assign it an ID to your liking`}
      </Alert>
      <Typography variant='h6' component='p' sx={{ mt: 2 }}>
        Take a picture
        <Tooltip
          title={<h2 style={{ color: 'lightgreen' }}>{tooltips.logKilnQ4}</h2>}
        >
          {<InfoIcon sx={{ width: 15 }} />}
        </Tooltip>
      </Typography>
      <TextField
        name='kiln_picture'
        type='file'
        value={kiln_picture}
        onChange={setData}
        fullWidth
      />
      <Divider />
      <Box style={{ height: 'auto' }}>
        <Typography variant='h4' sx={{ mb: 2, mt: 2 }}>
          Kiln List
        </Typography>
        <Divider />
        {kilnDimensions?.map(r => r.kilnType).includes('Ring of Fire') && (
          <>
            <Typography variant='h5' sx={{ mb: 2, mt: 2 }}>
              Ring of Fire Kilns
            </Typography>
            <TableContainer component={Paper} sx={{ height: 'auto' }}>
              {/* {loading ? <h1>Loading</h1> : */}
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    {ringOfFireTableColumns.map((c, i) => (
                      <TableCell align='left' key={i}>{c}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {kilnDimensions
                    ?.filter(r => r.kilnType === 'Ring of Fire')
                    .map((r, i) => {
                      return (
                        <TableRow
                          key={i}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell align='left'>{r.id}</TableCell>
                          <TableCell align='left'>
                            {new Date(r.createdAt).toDateString()}
                          </TableCell>
                          <TableCell align='left'>{r.kilnType}</TableCell>
                          <TableCell align='left'>{r.kilnId}</TableCell>
                          <TableCell align='left'>{r.units}</TableCell>
                          <TableCell align='left'>
                            {r.diameter}
                            {r.units === 'foot' ? ' ft' : ' m'}
                          </TableCell>
                          <TableCell align='left'>
                            {r.heightOfInnerPanels}
                            {r.units === 'foot' ? ' ft' : ' m'}
                          </TableCell>
                          <TableCell align='left'>
                            {r.heatShieldPanelWidth}
                            {r.units === 'foot' ? ' ft' : ' m'}
                          </TableCell>
                          <TableCell align='left'>
                            {r.heatShieldPanelLength}
                            {r.units === 'foot' ? ' ft' : ' m'}
                          </TableCell>
                          <TableCell align='left'>
                            {r.kilnFArea}
                            {r.units === 'foot' ? (
                              <>
                                {' '}
                                ft<sup>2</sup>
                              </>
                            ) : (
                              <>
                                {' '}
                                m<sup>2</sup>
                              </>
                            )}
                          </TableCell>
                          <TableCell align='left'>
                            {r.emptyVol}
                            {r.units === 'foot' ? (
                              <>
                                {' '}
                                ft<sup>3</sup>
                              </>
                            ) : (
                              <>
                                {' '}
                                m<sup>3</sup>
                              </>
                            )}
                          </TableCell>
                          <TableCell align='left'>
                            {r.panelArea}
                            {r.units === 'foot' ? (
                              <>
                                {' '}
                                ft<sup>2</sup>
                              </>
                            ) : (
                              <>
                                {' '}
                                m<sup>2</sup>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        {kilnDimensions?.map(r => r.kilnType).includes('Pit Kiln') && (
          <>
            <Typography variant='h5' sx={{ mb: 2, mt: 2 }}>
              Pit Kilns
            </Typography>
            <TableContainer component={Paper} sx={{ height: 'auto' }}>
              {/* {loading ? <h1>Loading</h1> : */}
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    {otherKilnTypeColumns.map((c, i) => (
                      <TableCell align='left' key={i}>{c}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {kilnDimensions
                    ?.filter(r => r.kilnType === 'Pit Kiln')
                    .map((r, i) => {
                      return (
                        <TableRow
                          key={i}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell align='left'>{r.id}</TableCell>
                          <TableCell align='left'>
                            {new Date(r.createdAt).toDateString()}
                          </TableCell>
                          {/* <TableCell align="left">
                      1
                    </TableCell>
                    <TableCell align="left">
                      2
                    </TableCell> */}
                          <TableCell align='left'>{r.kilnType}</TableCell>
                          <TableCell align='left'>{r.kilnId}</TableCell>
                          <TableCell align='left'>{r.units}</TableCell>
                          <TableCell align='left'>
                            {r.innerWidth}
                            {r.units === 'foot' ? ' ft' : ' m'}
                          </TableCell>
                          <TableCell align='left'>
                            {r.innerLength}
                            {r.units === 'foot' ? ' ft' : ' m'}
                          </TableCell>
                          <TableCell align='left'>
                            {r.innerHeight}
                            {r.units === 'foot' ? ' ft' : ' m'}
                          </TableCell>
                          <TableCell align='left'>
                            {r.kilnFArea}
                            {r.units === 'foot' ? (
                              <>
                                {' '}
                                ft<sup>2</sup>
                              </>
                            ) : (
                              <>
                                {' '}
                                m<sup>2</sup>
                              </>
                            )}
                          </TableCell>
                          <TableCell align='left'>
                            {r.emptyVol}
                            {r.units === 'foot' ? (
                              <>
                                {' '}
                                ft<sup>3</sup>
                              </>
                            ) : (
                              <>
                                {' '}
                                m<sup>3</sup>
                              </>
                            )}
                          </TableCell>
                          <TableCell align='left'>
                            {r.panelArea}
                            {r.units === 'foot' ? (
                              <>
                                {' '}
                                ft<sup>2</sup>
                              </>
                            ) : (
                              <>
                                {' '}
                                m<sup>2</sup>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        {kilnDimensions?.map(r => r.kilnType).includes('Big Box') && (
          <>
            <Typography variant='h5' sx={{ mb: 2, mt: 2 }}>
              Big Box Kilns
            </Typography>
            <TableContainer component={Paper} sx={{ height: 'auto' }}>
              {/* {loading ? <h1>Loading</h1> : */}
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    {otherKilnTypeColumns.map((c, i) => (
                      <TableCell align='left' key={i}>{c}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {kilnDimensions
                    ?.filter(r => r.kilnType === 'Big Box')
                    .map((r, i) => {
                      return (
                        <TableRow
                          key={i}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell align='left'>{r.id}</TableCell>
                          <TableCell align='left'>
                            {new Date(r.createdAt).toDateString()}
                          </TableCell>
                          {/* <TableCell align="left">
                      1
                    </TableCell>
                    <TableCell align="left">
                      2
                    </TableCell> */}
                          <TableCell align='left'>{r.kilnType}</TableCell>
                          <TableCell align='left'>{r.kilnId}</TableCell>
                          <TableCell align='left'>{r.units}</TableCell>
                          <TableCell align='left'>
                            {r.innerWidth}
                            {r.units === 'foot' ? ' ft' : ' m'}
                          </TableCell>
                          <TableCell align='left'>
                            {r.innerLength}
                            {r.units === 'foot' ? ' ft' : ' m'}
                          </TableCell>
                          <TableCell align='left'>
                            {r.innerHeight}
                            {r.units === 'foot' ? ' ft' : ' m'}
                          </TableCell>
                          <TableCell align='left'>
                            {r.kilnFArea}
                            {r.units === 'foot' ? (
                              <>
                                {' '}
                                ft<sup>2</sup>
                              </>
                            ) : (
                              <>
                                {' '}
                                m<sup>2</sup>
                              </>
                            )}
                          </TableCell>
                          <TableCell align='left'>
                            {r.emptyVol}
                            {r.units === 'foot' ? (
                              <>
                                {' '}
                                ft<sup>3</sup>
                              </>
                            ) : (
                              <>
                                {' '}
                                m<sup>3</sup>
                              </>
                            )}
                          </TableCell>
                          <TableCell align='left'>
                            {r.panelArea}
                            {r.units === 'foot' ? (
                              <>
                                {' '}
                                ft<sup>2</sup>
                              </>
                            ) : (
                              <>
                                {' '}
                                m<sup>2</sup>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        {(kilnDimensions
          ?.map(r => r.kilnType)
          .includes('Air Curtain Burner: BurnBoss') ||
          kilnDimensions
            ?.map(r => r.kilnType)
            .includes('Air Curtain Burner: CharBoss')) && (
            <>
              <Typography variant='h5' sx={{ mb: 2, mt: 2 }}>
                Air Curtain Burner Kilns
              </Typography>
              <TableContainer component={Paper} sx={{ height: 'auto' }}>
                {/* {loading ? <h1>Loading</h1> : */}
                <Table aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      {otherKilnTypeColumns.map((c, i) => (
                        <TableCell align='left' key={i}>{c}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {kilnDimensions
                      ?.filter(r => r.kilnType.includes('Air Curtain'))
                      .map((r, i) => {
                        return (
                          <TableRow
                            key={i}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell align='left'>{r.id}</TableCell>
                            <TableCell align='left'>
                              {new Date(r.createdAt).toDateString()}
                            </TableCell>
                            <TableCell align='left'>{r.kilnType}</TableCell>
                            <TableCell align='left'>{r.kilnId}</TableCell>
                            <TableCell align='left'>{r.units}</TableCell>
                            <TableCell align='left'>
                              {r.innerWidth}
                              {r.units === 'foot' ? ' ft' : ' m'}
                            </TableCell>
                            <TableCell align='left'>
                              {r.innerLength}
                              {r.units === 'foot' ? ' ft' : ' m'}
                            </TableCell>
                            <TableCell align='left'>
                              {r.innerHeight}
                              {r.units === 'foot' ? ' ft' : ' m'}
                            </TableCell>
                            <TableCell align='left'>
                              {r.kilnFArea}
                              {r.units === 'foot' ? (
                                <>
                                  {' '}
                                  ft<sup>2</sup>
                                </>
                              ) : (
                                <>
                                  {' '}
                                  m<sup>2</sup>
                                </>
                              )}
                            </TableCell>
                            <TableCell align='left'>
                              {r.emptyVol}
                              {r.units === 'foot' ? (
                                <>
                                  {' '}
                                  ft<sup>3</sup>
                                </>
                              ) : (
                                <>
                                  {' '}
                                  m<sup>3</sup>
                                </>
                              )}
                            </TableCell>
                            <TableCell align='left'>
                              {r.panelArea}
                              {r.units === 'foot' ? (
                                <>
                                  {' '}
                                  ft<sup>2</sup>
                                </>
                              ) : (
                                <>
                                  {' '}
                                  m<sup>2</sup>
                                </>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        {(kilnDimensions
          ?.map(r => r.kilnType)
          .includes('Conservation Burn - Hand Pile') ||
          kilnDimensions
            ?.map(r => r.kilnType)
            .includes('Conservation Burn - Machine Pile')) && (
            <>
              <Typography variant='h5' sx={{ mb: 2, mt: 2 }}>
                Conservation Burns
              </Typography>
              <TableContainer component={Paper} sx={{ height: 'auto' }}>
                {/* {loading ? <h1>Loading</h1> : */}
                <Table aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      {conservationBurnColumns.map((c, i) => (
                        <TableCell align='left' key={i}>{c}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {kilnDimensions
                      ?.filter(r => r.kilnType.includes('Conservation Burn - Hand Pile') || r.kilnType.includes('Conservation Burn - Machine Pile'))
                      .map((c, i) => {
                        return (
                          <TableRow
                            key={i}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell align='left'>{c.id}</TableCell>
                            <TableCell align='left'>
                              {new Date(c.createdAt).toDateString()}
                            </TableCell>
                            <TableCell align='left'>{c.kilnType}</TableCell>
                            <TableCell align='left'>{c.kilnId}</TableCell>
                            <TableCell align='left'>
                              {c.diameter}
                              {c.units === 'foot' ? (
                                <>
                                  {' '}
                                  ft<sup>3</sup>
                                </>
                              ) : (
                                <>
                                  {' '}
                                  m<sup>3</sup>
                                </>
                              )}
                            </TableCell>
                            <TableCell align='left'>
                              {c.height}
                              {c.units === 'foot' ? (
                                <>
                                  {' '}
                                  ft<sup>3</sup>
                                </>
                              ) : (
                                <>
                                  {' '}
                                  m<sup>3</sup>
                                </>
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
      </Box>
    </>
  );
}

const mapStateToProps = state => ({
  kilnDimensions: state.postFormData.kilnDimensions,
  loading: state.postFormData.loading,
});

const mapDispatchToProps = dispatch => {
  return {
    getKilnDimensions: id => dispatch(getKilnDimensions(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BF003aLogKiln);
