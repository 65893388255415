import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, styled } from '@mui/material';
import Home from './Home';
import MapTool from './MapTool';
import EcosystemAccount from './EcosystemAccount';
import Tokens from './Tokens';
import Contracts from './Contracts';
import Wallet from './Wallet';
import Support from './Support';
import Apply from '../sections/programmes/Apply';
import MyProgrammes from '../sections/programmes/MyProgrammes';
import { getUserDetails } from '../../actions/auth';
import ResourcesPage from './Resources';
import ProjectQuickLink from './ProjectQuickLink';
import CarbonFootprintingNew from '../sections/carbonfootprintingrework/CarbonFootprintingNew';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function MainLayout() {
  const { page, projectId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  const { userData } = useSelector(state => state.auth);

  return (
    <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
      <DrawerHeader />
      {page === 'map-tool' ? (
        <MapTool />
      ) : page === 'programs' ? (
        <MyProgrammes />
      ) : page === 'apply' ? (
        <Apply />
      ) : page === 'ecosystem-account' ? (
        <EcosystemAccount />
      ) : page === 'tokens' ? (
        <Tokens />
      ) : page === 'contracts' ? (
        <Contracts />
      ) : page === 'wallet' ? (
        <Wallet />
      ) : page === 'support-&-faqs' ? (
        <Support />
      ) : page === 'carbon-footprinting' ? (
        <CarbonFootprintingNew />
      ) : page === 'resources' ? (
        <ResourcesPage />
      ) : page === undefined && projectId ? (
        <ProjectQuickLink />
      ) : (
        <Home userData={userData} />
      )}
      {/* <Divider sx={{ mt: 10 }} />
      <Typography color='GrayText' marginTop={2}>
        &copy; 2022 - {moment().year()} | <strong>Ikhala Platform</strong>
      </Typography> */}
    </Box>
  );
}
