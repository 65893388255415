
/* REFRIGERANT GAS URLS */
import { setAlert } from '../../alert';
import {
    API_POST_REFRIGERANT_GAS_DATA,
    API_GET_REFRIGERANT_GAS_DATA,
    API_GET_REFRIGERANT_GAS_DATA_TOTALS,
} from '../constants/01-direct-ghg-emissions-and-removals-constants';

import {
    POST_REFRIGERANT_GAS_DATA_SUCCESS,
    POST_REFRIGERANT_GAS_DATA_FAIL,
    GET_REFRIGERANT_GAS_DATA_SUCCESS,
    GET_REFRIGERANT_GAS_DATA_FAIL,
    GET_REFRIGERANT_GAS_DATA_TOTALS_SUCCESS,
    GET_REFRIGERANT_GAS_DATA_TOTALS_FAIL,
} from '../types/01-direct-ghg-emissions-and-removals-types';

import axios from 'axios';

// const token = JSON.parse(localStorage.getItem('token'))?.token;

const config = {
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
};

// Fetch Plot List

export const postRefrigerantGasData = (formData, year, company, group, facility) => async dispatch => {
    try {
        const { data } = await axios.post(
            process.env.REACT_APP_API_URL + API_POST_REFRIGERANT_GAS_DATA + `/${year}/${company}/${group}/${facility}`,
            formData,
            config
        );
        dispatch({
            type: POST_REFRIGERANT_GAS_DATA_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: POST_REFRIGERANT_GAS_DATA_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getRefrigerantGasData = (year, facility) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_REFRIGERANT_GAS_DATA + `/${year}/${facility}`,
            config
        );
        dispatch({
            type: GET_REFRIGERANT_GAS_DATA_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_REFRIGERANT_GAS_DATA_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getRefrigerantGasDataTotals = (year, facility) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_REFRIGERANT_GAS_DATA_TOTALS + `/${year}/${facility}`,
            config
        );
        dispatch({
            type: GET_REFRIGERANT_GAS_DATA_TOTALS_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_REFRIGERANT_GAS_DATA_TOTALS_FAIL,
            payload: err.response.data.detail,
        });
    }
};
