export const GET_WATER_DATA_SUCCESS = 'GET_WATER_DATA_SUCCESS';
export const GET_WATER_DATA_FAIL = 'GET_WATER_DATA_FAIL';
export const POST_WATER_DATA_SUCCESS = 'POST_WATER_DATA_SUCCESS';
export const POST_WATER_DATA_FAIL = 'POST_WATER_DATA_FAIL';
export const GET_WATER_DATA_TOTALS_SUCCESS = 'GET_WATER_DATA_TOTALS_SUCCESS';
export const GET_WATER_DATA_TOTALS_FAIL = 'GET_WATER_DATA_TOTALS_FAIL';

export const GET_CHEMICALS_AND_ADDITIVES_DATA_SUCCESS = 'GET_CHEMICALS_AND_ADDITIVES_DATA_SUCCESS';
export const GET_CHEMICALS_AND_ADDITIVES_DATA_FAIL = 'GET_CHEMICALS_AND_ADDITIVES_DATA_FAIL';
export const POST_CHEMICALS_AND_ADDITIVES_DATA_SUCCESS = 'POST_CHEMICALS_AND_ADDITIVES_DATA_SUCCESS';
export const POST_CHEMICALS_AND_ADDITIVES_DATA_FAIL = 'POST_CHEMICALS_AND_ADDITIVES_DATA_FAIL';
export const GET_CHEMICALS_AND_ADDITIVES_DATA_TOTALS_SUCCESS = 'GET_CHEMICALS_AND_ADDITIVES_DATA_TOTALS_SUCCESS';
export const GET_CHEMICALS_AND_ADDITIVES_DATA_TOTALS_FAIL = 'GET_CHEMICALS_AND_ADDITIVES_DATA_TOTALS_FAIL';

export const GET_WOODEN_PRODUCTS_DATA_SUCCESS = 'GET_WOODEN_PRODUCTS_DATA_SUCCESS';
export const GET_WOODEN_PRODUCTS_DATA_FAIL = 'GET_WOODEN_PRODUCTS_DATA_FAIL';
export const POST_WOODEN_PRODUCTS_DATA_SUCCESS = 'POST_WOODEN_PRODUCTS_DATA_SUCCESS';
export const POST_WOODEN_PRODUCTS_DATA_FAIL = 'POST_WOODEN_PRODUCTS_DATA_FAIL';
export const GET_WOODEN_PRODUCTS_DATA_TOTALS_SUCCESS = 'GET_WOODEN_PRODUCTS_DATA_TOTALS_SUCCESS';
export const GET_WOODEN_PRODUCTS_DATA_TOTALS_FAIL = 'GET_WOODEN_PRODUCTS_DATA_TOTALS_FAIL';

export const GET_PACKAGING_MATERIALS_DATA_SUCCESS = 'GET_PACKAGING_MATERIALS_DATA_SUCCESS';
export const GET_PACKAGING_MATERIALS_DATA_FAIL = 'GET_PACKAGING_MATERIALS_DATA_FAIL';
export const POST_PACKAGING_MATERIALS_DATA_SUCCESS = 'POST_PACKAGING_MATERIALS_DATA_SUCCESS';
export const POST_PACKAGING_MATERIALS_DATA_FAIL = 'POST_PACKAGING_MATERIALS_DATA_FAIL';
export const GET_PACKAGING_MATERIALS_DATA_TOTALS_SUCCESS = 'GET_PACKAGING_MATERIALS_DATA_TOTALS_SUCCESS';
export const GET_PACKAGING_MATERIALS_DATA_TOTALS_FAIL = 'GET_PACKAGING_MATERIALS_DATA_TOTALS_FAIL';

export const GET_WINE_AND_GRAPES_DATA_SUCCESS = 'GET_WINE_AND_GRAPES_DATA_SUCCESS';
export const GET_WINE_AND_GRAPES_DATA_FAIL = 'GET_WINE_AND_GRAPES_DATA_FAIL';
export const POST_WINE_AND_GRAPES_DATA_SUCCESS = 'POST_WINE_AND_GRAPES_DATA_SUCCESS';
export const POST_WINE_AND_GRAPES_DATA_FAIL = 'POST_WINE_AND_GRAPES_DATA_FAIL';
export const GET_WINE_AND_GRAPES_DATA_TOTALS_SUCCESS = 'GET_WINE_AND_GRAPES_DATA_TOTALS_SUCCESS';
export const GET_WINE_AND_GRAPES_DATA_TOTALS_FAIL = 'GET_WINE_AND_GRAPES_DATA_TOTALS_FAIL';

export const GET_OTHER_DATA_SUCCESS = 'GET_OTHER_DATA_SUCCESS';
export const GET_OTHER_DATA_FAIL = 'GET_OTHER_DATA_FAIL';
export const POST_OTHER_DATA_SUCCESS = 'POST_OTHER_DATA_SUCCESS';
export const POST_OTHER_DATA_FAIL = 'POST_OTHER_DATA_FAIL';
export const GET_OTHER_DATA_TOTALS_SUCCESS = 'GET_OTHER_DATA_TOTALS_SUCCESS';
export const GET_OTHER_DATA_TOTALS_FAIL = 'GET_OTHER_DATA_TOTALS_FAIL';