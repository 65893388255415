import { setAlert } from '../../alert';
import {
    API_POST_CONVERSIONS_DATA,
    API_GET_CONVERSIONS_DATA,
} from '../constants/00-base-constants';
import {
    POST_CONVERSIONS_SUCCESS,
    POST_CONVERSIONS_FAIL,
    GET_CONVERSIONS_SUCCESS,
    GET_CONVERSIONS_FAIL
} from '../types/00-base-types';

import axios from 'axios';
// const token = JSON.parse(localStorage.getItem('token'))?.token;

const config = {
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
};

export const postConversions = (formData, year, company) => async dispatch => {
    try {
        const { data } = await axios.post(
            process.env.REACT_APP_API_URL + API_POST_CONVERSIONS_DATA + `/${year}/${company}`,
            formData,
            config
        );
        dispatch({
            type: POST_CONVERSIONS_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: POST_CONVERSIONS_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getConversions = (year, company) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_CONVERSIONS_DATA + `/${year}/${company}`,
            config
        );
        dispatch({
            type: GET_CONVERSIONS_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_CONVERSIONS_FAIL,
            payload: err.response.data.detail,
        });
    }
};