import { setAlert } from '../../alert';
import {
  API_POST_STATIONARY_OR_MOBILE_DIESEL_DATA,
  API_GET_STATIONARY_OR_MOBILE_DIESEL_DATA,
  API_GET_STATIONARY_OR_MOBILE_DIESEL_DATA_TOTALS,
  API_GET_HIGHEST_ID
} from '../constants/01-direct-ghg-emissions-and-removals-constants';
import {
  POST_STATIONARY_OR_MOBILE_DIESEL_DATA_SUCCESS,
  POST_STATIONARY_OR_MOBILE_DIESEL_DATA_FAIL,
  GET_STATIONARY_OR_MOBILE_DIESEL_DATA_SUCCESS,
  GET_STATIONARY_OR_MOBILE_DIESEL_DATA_FAIL,
  GET_STATIONARY_OR_MOBILE_DIESEL_DATA_TOTALS_SUCCESS,
  GET_STATIONARY_OR_MOBILE_DIESEL_DATA_TOTALS_FAIL,
  GET_HIGHEST_ID_SUCCESS,
  GET_HIGHEST_ID_FAIL
} from '../types/01-direct-ghg-emissions-and-removals-types';

import axios from 'axios';
// const token = JSON.parse(localStorage.getItem('token'))?.token;

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
};

export const postStationaryDieselData = (formData, type, year, company, group, facility) => async dispatch => {
  try {
    const { data } = await axios.post(
      process.env.REACT_APP_API_URL + API_POST_STATIONARY_OR_MOBILE_DIESEL_DATA + `/${type}/${year}/${company}/${group}/${facility}`,
      formData,
      config
    );
    dispatch({
      type: POST_STATIONARY_OR_MOBILE_DIESEL_DATA_SUCCESS,
      payload: data,
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: POST_STATIONARY_OR_MOBILE_DIESEL_DATA_FAIL,
      payload: err.response.data.detail,
    });
  }
};

export const getStationaryDieselData = (type, year, facility) => async dispatch => {
  try {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + API_GET_STATIONARY_OR_MOBILE_DIESEL_DATA + `/${type}/${year}/${facility}`,
      config
    );
    dispatch({
      type: GET_STATIONARY_OR_MOBILE_DIESEL_DATA_SUCCESS,
      payload: data,
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: GET_STATIONARY_OR_MOBILE_DIESEL_DATA_FAIL,
      payload: err.response.data.detail,
    });
  }
};

export const getStationaryDieselDataTotals = (type, year, facility) => async dispatch => {
  try {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + API_GET_STATIONARY_OR_MOBILE_DIESEL_DATA_TOTALS + `/${type}/${year}/${facility}`,
      config
    );
    dispatch({
      type: GET_STATIONARY_OR_MOBILE_DIESEL_DATA_TOTALS_SUCCESS,
      payload: data,
    });
    dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: GET_STATIONARY_OR_MOBILE_DIESEL_DATA_TOTALS_FAIL,
      payload: err.response.data.detail,
    });
  }
};

export const geHighestId = (type) => async dispatch => {
  try {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + API_GET_HIGHEST_ID + type,
      config
    );
    dispatch({
      type: GET_HIGHEST_ID_SUCCESS,
      payload: data,
    });
    // dispatch(setAlert(data.detail, 'success'));
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: GET_HIGHEST_ID_FAIL,
      payload: err.response.data.detail,
    });
  }
};
