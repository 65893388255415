import { Alert, Box, Button, Divider, Grid, Typography } from '@mui/material';
// import { useState } from 'react';

export default function GroupSelection({ setSelection, setGroup }) {

    const groups = ['Vinimark Trading', 'Reyneke Wines', 'Twee Jonge Gezellen']

    return (
        <Grid item xs={12}>
            <Button
                variant='outlined'
                size='large'
                sx={{ width: '20%', display: 'block', mt: 2.5, mb: 2.5 }}
                onClick={() => setSelection({ id: 7, title: 'variables' })}
            >
                Variables
            </Button>
            <Typography variant='h5'>Group</Typography>
            <Divider />
            <Alert severity='info'>Please select a group</Alert>
            <Box marginTop={2} sx={{ height: '100%' }}>
                {groups.map((g, i) => {
                    return (
                        <Button
                            key={i}
                            variant='contained'
                            size='large'
                            sx={{ width: '20%', display: 'block', mb: 2.5 }}
                            onClick={() => {
                                // console.log(g)
                                let groupId = ''
                                if (g === 'Vinimark Trading') {
                                    groupId = '1'
                                } else if (g === 'Reyneke Wines') {
                                    groupId = '2'
                                } else if (g === 'Twee Jonge Gezellen') {
                                    groupId = '3'
                                }
                                localStorage.setItem('group', groupId)
                                setGroup(g)
                                setSelection({ id: 3, title: 'facility' })
                            }}
                        >
                            {g}
                        </Button>
                    )
                })
                }


            </Box>
        </Grid>
    );
}
