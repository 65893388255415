import { useState, useEffect } from 'react';
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Typography,
  Paper,
  CircularProgress,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { getFormData } from '../../../actions/forms';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';

function FormData({ projectKey, formData, loading }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFormData(projectKey));
  }, [dispatch, projectKey]);

  // const { loading, formdata } = useSelector(state => state.postFormData);

  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [trimmedData, setTrimmedData] = useState([]);
  const [parsedData, setParsedData] = useState({});

  const handleClick = data => {
    setOpen(true);
    setData(data);
    setTrimmedData(data.formData);
    setParsedData(JSON.parse(data.formData));
  };


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const columns = [
    { field: '_id', headerName: 'ID' },
    { field: 'user', headerName: 'UserID' },
    { field: 'project', headerName: 'ProjectID', flex: 1 / 4 },
    { field: 'formId', headerName: 'FormID' },
    { field: 'formData', headerName: 'Form Data', flex: 1 },
    {
      field: 'created_at',
      headerName: 'Created On',
      valueGetter: params => {
        return moment(params.row.created_at).format('DD.MM.YYYY');
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      disableExport: true,
      renderCell: params => {
        return (
          <Button
            variant='contained'
            color='primary'
            onClick={() => handleClick(params.row)}
          >
            View
          </Button>
        );
      },
    },
  ];

  const items = [
    {
      id: 0,
      name: 'FPA Membership Certificate',
      file: parsedData?.fpa_membership_certificate?.split('\\')?.[2],
    },
    {
      id: 1,
      name: 'Firebreak Map',
      file: parsedData?.firebreak_map?.split('\\')?.[2],
    },
    {
      id: 2,
      name: 'Entity Doc',
      file: parsedData?.entity_doc?.split('\\')?.[2],
    },
    {
      id: 3,
      name: 'Membership Plans Doc',
      file: parsedData?.doc?.split('\\')?.[2],
    },
    {
      id: 4,
      name: 'FPA Certificate',
      file: parsedData?.fpa_certificate?.split('\\')?.[2],
    },
    {
      id: 5,
      name: 'Roads Doc',
      file: parsedData?.roads_doc?.split('\\')?.[2],
    },
    {
      id: 6,
      name: 'Singnage Doc',
      file: parsedData?.signage_doc?.split('\\')?.[2],
    },
    {
      id: 7,
      name: 'Gate Keys Doc',
      file: parsedData?.gate_keys_doc?.split('\\')?.[2],
    },
    {
      id: 8,
      name: 'Disable Electric Fences Doc',
      file: parsedData?.disable_electric_fences_doc?.split('\\')?.[2],
    },
    {
      id: 9,
      name: 'Turning Circles For Firetrucks Doc',
      file: parsedData?.turning_circles_for_firetrucks_doc?.split('\\')?.[2],
    },
    {
      id: 10,
      name: 'Bakkie Sakkie Doc',
      file: parsedData?.bakkie_sakkie_doc?.split('\\')?.[2],
    },
    {
      id: 11,
      name: 'Water Tanker 1000 Doc',
      file: parsedData?.water_tanker_1000_doc?.split('\\')?.[2],
    },
    {
      id: 12,
      name: 'Water Tanker 2000 Doc',
      file: parsedData?.water_tanker_2000_doc?.split('\\')?.[2],
    },
    {
      id: 13,
      name: 'Rake Holes Doc',
      file: parsedData?.rake_holes_doc?.split('\\')?.[2],
    },
    {
      id: 14,
      name: 'Beaters Doc',
      file: parsedData?.beaters_doc?.split('\\')?.[2],
    },
    {
      id: 15,
      name: 'Knapsacks Doc',
      file: parsedData?.knapsacks_doc?.split('\\')?.[2],
    },
    {
      id: 16,
      name: 'Drop Torch Doc',
      file: parsedData?.drip_torch_doc?.split('\\')?.[2],
    },
    {
      id: 17,
      name: 'Cellphone Doc',
      file: parsedData?.cellphone_doc?.split('\\')?.[2],
    },
    {
      id: 18,
      name: 'Handheld Doc',
      file: parsedData?.handheld_doc?.split('\\')?.[2],
    },
    {
      id: 19,
      name: 'Mobile Radio Doc',
      file: parsedData?.mobile_radio_doc?.split('\\')?.[2],
    },
    {
      id: 20,
      name: 'First Aid Kit Doc',
      file: parsedData?.first_aid_kit_doc?.split('\\')?.[2],
    },
    {
      id: 21,
      name: 'Firefighters Doc',
      file: parsedData?.firefighters_doc?.split('\\')?.[2],
    },
    {
      id: 22,
      name: 'Crew Leaders Doc',
      file: parsedData?.crew_leaders_doc?.split('\\')?.[2],
    },
  ];

  const awsPath =
    'https://ikhala-static-content.s3.eu-central-1.amazonaws.com/uploaded_docs/';

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box sx={{ width: '95%' }}>
        <Alert severity='info' sx={{ my: 2 }}>
          Veldfire Management Forms do not have a ProjectID
        </Alert>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Box style={{ height: 500, width: '100%' }}>
              <DataGrid
                rows={formData}
                getRowId={row => row._id}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                components={{ Toolbar: GridToolbar }}
              />
            </Box>
            <Modal
              open={open}
              onClose={() => setOpen(false)}
              aria-labelledby='modal-modal-title'
              aria-describedby='modal-modal-description'
            >
              <Box sx={style}>
                <Typography id='modal-modal-title' variant='h6' component='h2'>
                  Form Data
                </Typography>
                <Typography
                  id='modal-modal-description'
                  sx={{ mt: 2, wordBreak: 'break-all' }}
                >
                  {trimmedData}
                </Typography>
                <Divider sx={{ my: 2 }} />
                {items.map(
                  item =>
                    item.file && (
                      <Grid
                        key={item.id}
                        container
                        spacing={5}
                        sx={{ placeItems: 'center', mb: 2 }}
                      >
                        <Grid item xs={3}>
                          <Typography>{item.name}</Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <Button
                            variant='contained'
                            onClick={() =>
                              window.open(
                                `${awsPath}${data?.userId}_${data?.formId}_${item.file}`
                              )
                            }
                          >
                            Download file
                          </Button>
                        </Grid>
                      </Grid>
                    )
                )}
                <Divider sx={{ my: 2 }} />
                <Box sx={{ textAlign: 'right' }}>
                  <Button variant='outlined' onClick={() => setOpen(false)}>
                    Close
                  </Button>
                </Box>
              </Box>
            </Modal>
          </>
        )}
      </Box>
    </Paper>
  );
}

const mapStateToProps = state => ({
  formData: state.postFormData.formdata,
  loading: state.postFormData.loading,
});

const mapDispatchToProps = dispatch => {
  return {
    getFormData: projectKey => dispatch(getFormData(projectKey))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormData);
