import { useEffect } from 'react';
import {
  // Button,
  // Card,
  // CardActions,
  // CardContent,
  Grid,
  Typography,
  Alert,
  Box
} from '@mui/material';
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux';
import { getProjects } from '../../actions/forms';
import { useDispatch } from 'react-redux';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';

const Home = ({ projects }) => {
  const dispatch = useDispatch();
  // const [projectId, setProjectId] = useState(null);
  // const [projectKey, setProjectKey] = useState(null);
  // const [nickname, setNickname] = useState('');
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getProjects());
  }, [dispatch]);

  const handleNavigate = (project) => {
    navigate(`/project/${project._id}?nickname=${project.nickname}&projectIdentifier=${project.projectId}`)
    // setProjectId(project.projectId);
    // setNickname(project.nickname);
    // setProjectKey(project._id)
    // navigate('/programmes', { replace: true, state: { projectId: project.projectId, projectKey: project._id } })
  }


  return (
    <>
      <Typography variant="h4" component="h4" sx={{ mb: 3 }}>
        Quick Project Access
      </Typography>
      <Grid xs={12} display="flex" alignContent={'space-between'} flexWrap={'wrap'}>
        {projects?.map((project, index) => (
          <>
            <Box className="biochar-project biochar-project-home" key={index}
              onClick={() => handleNavigate(project)}>
              <Typography sx={{ fontSize: '18px' }}><BookmarkBorderOutlinedIcon sx={{ mb: '-7px' }} /> {project.projectId}</Typography>
              <Typography sx={project.nickname ? { fontSize: '14px' } : { visibility: 'hidden' }} mt={3}><strong>Nickname:</strong> {project.nickname}</Typography>
              <Typography sx={{ fontSize: '14px' }}><strong>Date Created:</strong> {project.created_at.split('T')[0]}</Typography>
            </Box >

            {/* <Card
              key={project._id}
              variant='outlined'
              sx={{
                textAlign: 'center',
                bgcolor: '#eee',
                minWidth: '300px'
              }}
            >
              <CardContent>
                <Typography variant='h5'>{project.nickname}</Typography>
                <Typography variant='h6'>{project.projectId}</Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: 'center' }}>
                <Button
                  variant='outlined'
                  size='small'
                  sx={{ mt: 1 }}
                  onClick={() => handleNavigate(project)}
                >
                  Open
                </Button>
              </CardActions>
            </Card> */}
          </>
        ))}
      </Grid >
      <Alert severity='warning' sx={{ width: '100%', position: 'absolute', bottom: '0', left: '0', paddingLeft: '80px' }}>
        Please email wihan@ikhala.tech for the “Web App User Manual” and the “CHARR Mobile App Field Guide”, as well as other biochar support documents such as the “Biochar Toolkit Overview” and “Tips for Data Quality Improvements”
      </Alert>
    </>
  );
};

const mapStateToProps = state => ({
  projects: state.postFormData.projects,
  loading: state.postFormData.loading,
});

const mapDispatchToProps = dispatch => {
  return {
    getProjects: () => dispatch(getProjects()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);