import { useState } from 'react';
import {
  Button,
  Modal,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Divider,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

export default function AddItem({
  formFields,
  initialState,
  onFormSubmit,
  items,
  addTitle
}) {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(initialState);

  const handleInputChange = (e, fieldName) => {
    if (e.target) {
      let { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    } else {
      setFormData({
        ...formData,
        [fieldName]: moment(e._d).format('YYYY-MM-DD'),
      });
    }
  }

  const handleSubmit = () => {
    onFormSubmit(formData);
    setOpen(false);
  };

  const renderFormField = field => {
    if (field.type === 'text') {
      return (
        <TextField
          fullWidth
          name={field.name}
          label={field.label}
          value={formData[field.name] || ''}
          onChange={handleInputChange}
          type="text"
        />
      );
    } else if (field.type === 'number') {
      return (
        <TextField
          fullWidth
          name={field.name}
          label={field.label}
          value={formData[field.name] || ''}
          onChange={handleInputChange}
          type="number"
        />
      )
    }
    else if (field.type === 'select') {
      return (
        <FormControl fullWidth>
          <InputLabel sx={{ background: 'white' }}>{field.label}</InputLabel>
          <Select
            name={field.name}
            value={formData[field.name] || ''}
            onChange={handleInputChange}
          >
            {field.options.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
    else if (field.type === 'date') {
      return (
        <FormControl fullWidth>
          {field.label}
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker name={field.name} onChange={(e) => handleInputChange(e, field.name)} />
          </LocalizationProvider>
        </FormControl>
      );
    }
  };

  return (
    <Box>
      {/* <Typography variant='h6' style={{ marginBottom: '20px' }}>
        Add Item
      </Typography> */}
      <Button variant='contained' color='primary' onClick={() => setOpen(true)}>
        {addTitle ? addTitle : 'Add new'}
      </Button>
      <Divider sx={{ mt: '1rem' }} />
      <Modal open={open} onClose={() => setOpen(false)} sx={{ width: '25%', margin: '2rem auto', overflow: 'scroll' }}>
        <Paper style={{ padding: '20px' }}>
          {formFields.map((field, index) => (
            <div key={index} style={{ marginBottom: '20px' }}>
              {renderFormField(field)}
            </div>
          ))}
          <Button variant='contained' onClick={handleSubmit}>
            Submit
          </Button>
        </Paper>
      </Modal>
      <List>
        {items.length > 0 && items.map((item, index) => (
          <ListItem key={index}>
            <ListItemText primary={item.machineName} secondary={item.purpose} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
