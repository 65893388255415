
/* REFRIGERANT GAS URLS */
import { setAlert } from '../../alert';

import {
    GET_WASTE_TO_RECYCLING_DATA_SUCCESS,
    GET_WASTE_TO_RECYCLING_DATA_FAIL,
    POST_WASTE_TO_RECYCLING_DATA_SUCCESS,
    POST_WASTE_TO_RECYCLING_DATA_FAIL,
    GET_WASTE_TO_RECYCLING_DATA_TOTALS_SUCCESS,
    GET_WASTE_TO_RECYCLING_DATA_TOTALS_FAIL
} from '../types/05-indirect-ghg-emissions-from-other-sources';

import {
    GET_WASTE_TO_RECYCLING_DATA,
    POST_WASTE_TO_RECYCLING_DATA,
    GET_WASTE_TO_RECYCLING_DATA_TOTALS,
} from '../constants/05-indirect-ghg-emissions-from-other-sources';

import axios from 'axios';

// const token = JSON.parse(localStorage.getItem('token'))?.token;

const config = {
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
};

export const postWasteToRecyclingData = (formData, year, company, group, facility) => async dispatch => {
    try {
        const { data } = await axios.post(
            process.env.REACT_APP_API_URL + POST_WASTE_TO_RECYCLING_DATA + `/${year}/${company}/${group}/${facility}`,
            formData,
            config
        );
        dispatch({
            type: POST_WASTE_TO_RECYCLING_DATA_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: POST_WASTE_TO_RECYCLING_DATA_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getWasteToRecyclingData = (year, facility) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + GET_WASTE_TO_RECYCLING_DATA + `/${year}/${facility}`,
            config
        );
        dispatch({
            type: GET_WASTE_TO_RECYCLING_DATA_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_WASTE_TO_RECYCLING_DATA_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getWasteToRecyclingDataTotals = (year, facility) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + GET_WASTE_TO_RECYCLING_DATA_TOTALS + `/${year}/${facility}`,
            config
        );
        dispatch({
            type: GET_WASTE_TO_RECYCLING_DATA_TOTALS_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_WASTE_TO_RECYCLING_DATA_TOTALS_FAIL,
            payload: err.response.data.detail,
        });
    }
};
