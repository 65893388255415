import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_PROGRAMMES_SUCCESS,
  USER_PROGRAMMES_FAIL,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAIL,
  CF_USER_DETAILS_FAIL,
  CF_USER_DETAILS_SUCCESS
} from './types';
import {
  API_REGISTER,
  API_LOGIN,
  API_LOGOUT,
  API_USER_DETAILS,
  API_CF_USER_DETAILS,
  API_USER_PROGRAMMES,
  API_USER_PROFILE,
} from './constants';
import { setAlert } from './alert';
import axios from 'axios';

// // const token = JSON.parse(localStorage.getItem('token'))?.token;

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true
};

const protectConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true
};

// Register User
export const register = formData => async dispatch => {
  try {
    const { data } = await axios.post(
      process.env.REACT_APP_API_URL + API_REGISTER,
      formData,
      config
    );
    dispatch(setAlert('Registration Successful', 'success'));
    dispatch({
      type: REGISTER_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

// Login User
export const login =
  ({ username, email, password }) =>
    async dispatch => {
      const body = JSON.stringify({ username, email, password });
      try {
        const { data } = await axios.post(
          process.env.REACT_APP_API_URL + API_LOGIN,
          body,
          config
        );
        dispatch(setAlert('Logged In Successfully', 'success'));
        dispatch({
          type: LOGIN_SUCCESS,
          payload: data,
        });
      } catch (err) {
        console.log(err)
        dispatch(setAlert(err.response.data.error, 'error'));
        dispatch({
          type: LOGIN_FAIL,
        });
        throw (err)
      }
    };

// Logout User
export const logout = () => async dispatch => {
  dispatch(setAlert('Logged Out Successfully', 'success'));
  try {
    const { data } = await axios.post(
      process.env.REACT_APP_API_URL + API_LOGOUT,
      protectConfig
    );
    dispatch({
      type: LOGOUT,
      payload: data,
    });
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: LOGOUT,
    });
  }
};

// Get Specific User Info
export const getUserProfile = () => async dispatch => {
  try {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + API_USER_PROFILE,
      protectConfig
    );
    dispatch({
      type: USER_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: USER_PROFILE_FAIL,
    });
  }
};

// Get Additional User Data
export const getUserDetails = () => async dispatch => {
  try {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + API_USER_DETAILS,
      protectConfig
    );
    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: USER_DETAILS_FAIL,
    });
  }
};

export const getCfUserDetails = () => async dispatch => {
  try {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + API_CF_USER_DETAILS,
      protectConfig
    );
    dispatch({
      type: CF_USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: CF_USER_DETAILS_FAIL,
    });
  }
};

// Get User Programmes
export const getUserProgrammes = () => async dispatch => {
  try {
    const { data } = await axios.get(
      process.env.REACT_APP_API_URL + API_USER_PROGRAMMES,
      protectConfig
    );
    dispatch({
      type: USER_PROGRAMMES_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: USER_PROGRAMMES_FAIL,
    });
  }
};

// Add or Update User Info
export const addOrUpdateUserProfile = (info, type) => async dispatch => {
  try {
    const api = process.env.REACT_APP_API_URL + API_USER_PROFILE;
    const { data } =
      type === 'update'
        ? await axios.put(api, info, protectConfig)
        : await axios.post(api, info, protectConfig);
    dispatch(setAlert(data.detail, 'success'));
    dispatch({
      type: USER_PROFILE_SUCCESS,
      payload: data?.profileData,
    });
  } catch (err) {
    dispatch(setAlert(err.response.data.detail, 'error'));
    dispatch({
      type: USER_PROFILE_FAIL,
    });
  }
};
