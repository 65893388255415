import { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material';
import {
  Menu,
  ChevronLeft,
  ChevronRight,
  Home,
  Co2,
  GridView,
  SupportAgent,
  HomeRepairService,
} from '@mui/icons-material';
import MainLayout from './MainLayout';
import { APP_NAME } from '../../actions/constants';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import {
  Button,
  Grid,
  Box,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
// import Login from '../auth/Login';
import { logout } from '../../actions/auth';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Notification from './Notification';
import { useDispatch, useSelector } from 'react-redux';
import { getCfUserDetails } from '../../actions/auth';

const drawerWidth = 240;

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const Main = ({ logout }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  // const [openLogin, setOpenLogin] = useState(false);
  const { cfUserData } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCfUserDetails());
  }, [dispatch]);

  const adminRoles = cfUserData?.admin_roles?.map(e => e.role.toLowerCase());

  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const { page } = useParams();

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  let items = [
    { name: 'Home', component: <Home /> },
    { name: 'Programs', component: <GridView /> },
    { name: 'Carbon Footprinting', component: <Co2 /> },
    { name: 'Resources', component: <HomeRepairService /> },
    { name: 'Support & FAQs', component: <SupportAgent /> },
    // { name: '', component: <Inbox /> }
  ];

  if (!adminRoles?.includes('overview')) {
    items = items.filter(i => i.name !== 'Carbon Footprinting');
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Notification />
      <CssBaseline />
      <AppBar position='fixed' open={open}>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <Menu />
          </IconButton>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant='h6'>{APP_NAME}</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              {/* <Button
                variant='outlined'
                color='tertiary'
                sx={{ mr: 2 }}
                onClick={() => navigate('/dashboard')}
              >
                Dashboard
              </Button> */}

              <Button
                variant='contained'
                color='secondary'
                onClick={handleLogout}
              >
                Logout
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer variant='permanent' open={open}>
        <DrawerHeader>
          <Typography variant='h5'>Dashboard</Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {items.map((item, index) => (
            <ListItemButton
              key={index}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
                bgcolor:
                  item.name.toLowerCase().split(' ').join('-') === page
                    ? '#eee'
                    : '',
                // display: (!adminRoles?.includes('overview') && index === 2) ? 'none' : 'none'
              }}
              component={Link}
              to={'/' + item.name.toLowerCase().split(' ').join('-')}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {item.component}
              </ListItemIcon>
              <ListItemText
                primary={item.name}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          ))}
        </List>
      </Drawer>
      <MainLayout />
      {/* <Modal open={openLogin} onClose={() => setOpenLogin(false)}>
        <Login openLogin={val => setOpenLogin(val)} />
      </Modal> */}
    </Box>
  );
};

Main.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default connect(null, { logout })(Main);
